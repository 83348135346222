import React, { useState, useEffect } from "react";
import Axios from "axios";
import imageCompression from "browser-image-compression";
import Admin_table from "./Admin_table";
const Admin_Partners = () => {
  // 기관명 등록 모달창
  const [addTypeModal, setAddTypeModal] = useState(false);
  // 저장할 기관명 리스트
  const [addTypeList, setAddTypeList] = useState({
    TYPE: "",
  });

  const onClickAdd = async (key) => {
    if (key === "addType") {
      if (addTypeList.TYPE === "") {
        alert("내용이 작성되지 않았습니다.");
      } else {
        if (window.confirm("등록하시겠습니까?")) {
          await Axios.post("http://34.64.112.233:8000/post/partners_type", {
            addTypeList,
          })
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } else if (key === "addForm") {
      if (addPartners_form.TYPE_ID === "" || !addPartnersImg) {
        alert("내용이 작성되지 않았습니다.");
      } else {
        if (window.confirm("등록하시겠습니까?")) {
          const formDate = new FormData();
          formDate.append("file", addPartnersImg ? addPartnersImg : "");
          formDate.append(
            "fileName",
            addPartnersImg ? addPartnersImg.name : ""
          );
          formDate.append("partners_form", JSON.stringify(addPartners_form));
          await Axios.post(
            "http://34.64.112.233:8000/post/partners_form",
            formDate
          )
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };
  // 파트너스 모달창
  const [addPartnersModal, setAddPartnersModal] = useState(false);
  // 저장할 파트너스
  const [addPartners_form, setAddPartners_form] = useState({
    TYPE_ID: "",
    SHOW_YN: "Y",
  });
  // 저장할 파트너스 이미지
  const [addPartnersImg, setAddPartnersImg] = useState();
  // DB에 저장된 기관명 리스트
  const [getTypeList, setGetTypeList] = useState([]);
  useEffect(() => {
    Axios.get("http://34.64.112.233:8000/get/partners_type")
      .then((res) => {
        const resData = res.data;
        setGetTypeList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 이미지 resize
  const handleFileOnChange = async (e) => {
    let file = e.target.files[0]; // 입력받은 file객체

    // 이미지 옵션 설정
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 200,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setAddPartnersImg(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="Admin_inner">
        <p className="Admin_table_title">기관명</p>
        <div className="Admin_add_btn">
          <button
            onClick={() => {
              setAddTypeModal(true);
            }}
          >
            등록
          </button>
        </div>
        <Admin_table table_name="Partners_type_list" />
        <p className="Admin_table_title">파트너스</p>
        <div className="Admin_add_btn">
          <button
            onClick={() => {
              setAddPartnersModal(true);
            }}
          >
            등록
          </button>
        </div>
        <Admin_table table_name="Partners_form" />
      </div>
      {addTypeModal && (
        <div className="Admin_edit_modal">
          <div className="Admin_edit_modal_inner_top">
            <p>등록</p>
            <img
              src="../img/pg_close.png"
              alt="img"
              onClick={() => {
                setAddTypeModal(false);
              }}
            />
          </div>
          <div className="Admin_edit_modal_inner_form">
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">기관명</p>
              <input
                type="text"
                name="TYPE"
                onChange={(e) => {
                  const { name, value } = e.target;
                  setAddTypeList({
                    ...addTypeList,
                    [name]: value,
                  });
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="Admin_edit_modal_inner_btn">
            <button
              onClick={() => {
                setAddTypeModal(false);
              }}
            >
              취소
            </button>
            <button
              onClick={() => {
                onClickAdd("addType");
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}

      {addPartnersModal && (
        <div className="Admin_edit_modal">
          <div className="Admin_edit_modal_inner_top">
            <p>등록</p>
            <img
              src="../img/pg_close.png"
              alt="img"
              onClick={() => {
                setAddPartnersModal(false);
              }}
            />
          </div>
          <div className="Admin_edit_modal_inner_form">
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">기관명</p>
              <select
                name="TYPE_ID"
                onChange={(e) => {
                  const { name, value } = e.target;
                  setAddPartners_form({
                    ...addPartners_form,
                    [name]: value,
                  });
                }}
              >
                <option>선택</option>
                {getTypeList.map((item, index) => (
                  <option key={index} value={item.TYPE_ID}>
                    {item.TYPE}
                  </option>
                ))}
              </select>
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <input type="file" id="main_file" onChange={handleFileOnChange} />
              <p className="Admin_edit_modal_inner_form_input_title">이미지</p>
              <label htmlFor="main_file">
                <p>{addPartnersImg ? addPartnersImg.name : ""}</p>
              </label>
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">
                표시여부
              </p>
              <select
                name="SHOW_YN"
                onChange={(e) => {
                  const { name, value } = e.target;
                  setAddPartners_form({
                    ...addPartners_form,
                    [name]: value,
                  });
                }}
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </div>
          </div>
          <div className="Admin_edit_modal_inner_btn">
            <button
              onClick={() => {
                setAddPartnersModal(false);
              }}
            >
              취소
            </button>
            <button
              onClick={() => {
                onClickAdd("addForm");
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default Admin_Partners;

import "./App.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Admin_Header from "./Admin_Header";
import Footer from "./Footer";
import RouterPages from "./Router/RouterPages";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  // 관리자 로그인, 관리자 페이지 이동 시 헤더 false
  const [adminLogin, setAdminLogin] = useState(true);
  // 관리자 헤더
  const [adminHeader, setAdminHeader] = useState(false);
  useEffect(() => {
    const pathName = location.pathname;

    pathName.slice(0, 6) === "/admin"
      ? setAdminLogin(false)
      : setAdminLogin(true);

    pathName.slice(0, 6) !== "/admin" || pathName === "/admin/login"
      ? setAdminHeader(false)
      : setAdminHeader(true);
  }, []);

  return (
    <div className={adminHeader ? "App Admin_App" : "App"}>
      {adminLogin && <Header />}
      {adminHeader && <Admin_Header />}
      <div className={adminHeader ? "Router_box" : ""}>
        <RouterPages />
      </div>

      {adminLogin && <Footer />}
    </div>
  );
}

export default App;

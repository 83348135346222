import "./Admin_About.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import imageCompression from "browser-image-compression";
import Admin_table from "./Admin_table";

const Admin_Space = () => {
  // 계정 등록 모달창
  const [addSignUpModal, setAddSignUpModal] = useState(false);
  // 계정 생성 폼
  const [addSignUp_form, setAddSignUp_form] = useState({
    ID: "",
    NAME: "",
    EMAIL: "",
    PHONE: "",
    COMPANY: "",
    USE_YN: "Y",
  });
  const SignUpGetValue = (e) => {
    const { name, value } = e.target;
    setAddSignUp_form({
      ...addSignUp_form,
      [name]: value,
    });
  };

  // 좌석 등록 모달창
  const [addPlaceModal, setAddPlaceModal] = useState(false);
  // 좌석 등록 폼
  const [addPlace_form, setAddPlace_form] = useState({
    LOC_CATE: "",
    LOC_NAME: "",
    USE_YN: "Y",
  });
  const placeGetValue = (e) => {
    const { name, value } = e.target;
    setAddPlace_form({
      ...addPlace_form,
      [name]: value,
    });
  };
  // 저장할 좌석 이미지
  const [addPlaceImg, setAddPlaceImg] = useState();

  const onClickAdd = async (key) => {
    if (key === "addPlace") {
      if (
        addPlace_form.LOC_CATE === "" ||
        addPlace_form.LOC_NAME === "" ||
        !addPlaceImg
      ) {
        alert("내용이 작성되지 않았습니다.");
      } else {
        if (window.confirm("등록하시겠습니까?")) {
          const formDate = new FormData();
          formDate.append("file", addPlaceImg ? addPlaceImg : "");
          formDate.append("fileName", addPlaceImg ? addPlaceImg.name : "");
          formDate.append("addPlace_form", JSON.stringify(addPlace_form));
          await Axios.post(
            "http://34.64.112.233:8000/post/space_place",
            formDate
          )
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } else if (key === "addSignUp") {
      if (
        addSignUp_form.ID === "" ||
        addSignUp_form.NAME === "" ||
        addSignUp_form.EMAIL === "" ||
        addSignUp_form.PHONE === "" ||
        addSignUp_form.COMPANY === ""
      ) {
        alert("내용이 작성되지 않았습니다.");
      } else {
        if (window.confirm("등록하시겠습니까?")) {
          Axios.post("http://34.64.112.233:8000/post/space_signUp", {
            addSignUp_form,
          }).then((res) => {
            if (res.data === "t") {
              alert("계정생성 성공");
              window.location.reload();
            } else if (res.data === "n") {
              alert("아이디 중복");
            } else {
              alert("계정생성 실패");
            }
          });
        }
      }
    }
  };
  // 예약가능여부
  const [spaceUse, setSpaceUse] = useState();
  useEffect(() => {
    Axios.get("http://34.64.112.233:8000/get/Home")
      .then((res) => {
        const resData = res.data;

        setSpaceUse(resData[0].SPACE_USE_YN);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickSpaceUse = async (e) => {
    const value = e.target.value;
    setSpaceUse(value);
    await Axios.put("http://34.64.112.233:8000/put/space/use", {
      value,
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 이미지 resize
  const handleFileOnChange = async (e) => {
    let file = e.target.files[0]; // 입력받은 file객체

    // 이미지 옵션 설정
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 800,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setAddPlaceImg(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="Admin_inner">
        <p className="Admin_table_title">예약가능 여부</p>
        <select
          style={{ display: "flex", marginLeft: "15px" }}
          value={spaceUse}
          onChange={onClickSpaceUse}
        >
          <option value="Y">가능</option>
          <option value="N">불가능</option>
        </select>
        <p className="Admin_table_title">좌석 등록</p>
        <div className="Admin_add_btn">
          <button
            onClick={() => {
              setAddPlaceModal(true);
            }}
          >
            등록
          </button>
        </div>
        <Admin_table table_name="Space_Place_form" />
        <p className="Admin_table_title">계정 등록</p>
        <div className="Admin_add_btn">
          <button
            onClick={() => {
              setAddSignUpModal(true);
            }}
          >
            등록
          </button>
        </div>
        <Admin_table table_name="Space_User" />
        <p className="Admin_table_title">예약현황</p>
        <Admin_table table_name="Space_Place_Reservation" />
      </div>
      {addSignUpModal && (
        <div className="Admin_edit_modal">
          <div className="Admin_edit_modal_inner_top">
            <p>등록</p>
            <img
              src="../img/pg_close.png"
              alt="img"
              onClick={() => {
                setAddSignUpModal(false);
              }}
            />
          </div>
          <div className="Admin_edit_modal_inner_form">
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">아이디</p>
              <input
                type="text"
                name="ID"
                onChange={SignUpGetValue}
                autoComplete="off"
              />
            </div>

            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">
                대표자명
              </p>
              <input
                type="text"
                name="NAME"
                onChange={SignUpGetValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">이메일</p>
              <input
                type="text"
                name="EMAIL"
                onChange={SignUpGetValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">연락처</p>
              <input
                type="text"
                name="PHONE"
                onChange={SignUpGetValue}
                autoComplete="off"
                placeholder="'-' 없이 입력하세요."
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">소속명</p>
              <input
                type="text"
                name="COMPANY"
                onChange={SignUpGetValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">
                사용가능 유무
              </p>
              <select name="USE_YN" onChange={placeGetValue}>
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </div>
          </div>
          <div className="Admin_edit_modal_inner_btn">
            <button
              onClick={() => {
                setAddSignUpModal(false);
              }}
            >
              취소
            </button>
            <button
              onClick={() => {
                onClickAdd("addSignUp");
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
      {addPlaceModal && (
        <div className="Admin_edit_modal">
          <div className="Admin_edit_modal_inner_top">
            <p>등록</p>
            <img
              src="../img/pg_close.png"
              alt="img"
              onClick={() => {
                setAddPlaceModal(false);
              }}
            />
          </div>
          <div className="Admin_edit_modal_inner_form">
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">장소</p>
              <input
                type="text"
                name="LOC_CATE"
                onChange={placeGetValue}
                autoComplete="off"
              />
            </div>

            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">좌석명</p>
              <input
                type="text"
                name="LOC_NAME"
                onChange={placeGetValue}
                autoComplete="off"
              />
            </div>

            <div className="Admin_edit_modal_inner_form_input">
              <input type="file" id="main_file" onChange={handleFileOnChange} />
              <p
                className="Admin_edit_modal_inner_form_input_title"
                // title="1920 x 782 px"
              >
                이미지
              </p>
              <label htmlFor="main_file">
                <p>{addPlaceImg ? addPlaceImg.name : ""}</p>
              </label>
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">
                사용유무
              </p>
              <select name="USE_YN" onChange={placeGetValue}>
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </div>
          </div>
          <div className="Admin_edit_modal_inner_btn">
            <button
              onClick={() => {
                setAddPlaceModal(false);
              }}
            >
              취소
            </button>
            <button
              onClick={() => {
                onClickAdd("addPlace");
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default Admin_Space;

import "./Admin_About.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import Admin_table from "./Admin_table";

const Admin_Contact_us_form = () => {
  return (
    <>
      <div className="Admin_inner">
        <p className="Admin_table_title">문의요청</p>

        <Admin_table table_name="Contact_us_form" />
      </div>
    </>
  );
};
export default Admin_Contact_us_form;

import "./Header.css";
import { NavLink, Link } from "react-router-dom";
const Header = () => {
  const navList = [
    {
      title: "ABOUT",
      link: "/About",
    },
    {
      title: "PROGRAM",
      link: "/Program",
    },
    // {
    //   title: "SPACE",
    //   link: "/Space",
    // },
    {
      title: "PORTFOLIO",
      link: "/Portfolio",
    },
    {
      title: "PARTNERS",
      link: "/Partners",
    },
    {
      title: "NEWS",
      link: "/News",
    },
    {
      title: "CONTACT US",
      link: "/Contact_us",
    },
  ];

  return (
    <>
      <div className="Header_inner">
        <Link to="/">
          <img src="/img/피지로고_화이트 1.png" alt="img" />
        </Link>
        <div className="Header_nav">
          {navList.map((item, index) => (
            <div key={index}>
              <NavLink to={item.link}>
                <p>{item.title}</p>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Header;

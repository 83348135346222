import "./Contact_us.css";
import React, { useState } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Contact_us_form from "./Contact_us_form";
const Contact_us = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // 문의 유형 리스트
  const formList = [
    {
      title: "스타트업\n액설레이팅 제안",
      // text1: "스타트업을 하시는분",
      // text2: "성장하고 나아가고 싶은 분",
      // text3: "00000분",
      // text4: "00000분",
      // text5: "00000분",
    },
    {
      title: "회사/기관\n제휴 문의",
      // text1: "스타트업을 하시는분",
      // text2: "성장하고 나아가고 싶은 분",
      // text3: "00000분",
      // text4: "00000분",
      // text5: "00000분",
    },
    {
      title: "일반/기타\n문의",
      // text1: "스타트업을 하시는분",
      // text2: "성장하고 나아가고 싶은 분",
      // text3: "00000분",
      // text4: "00000분",
      // text5: "00000분",
    },
  ];
  // 유형 클릭 시 제목만 담을 변수
  const [contactUsType, setContactUsType] = useState("");

  // 사업계획서 양식
  const downloadFile = (url, fileName) => {
    fetch(url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 10000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };

  return (
    <>
      {location.pathname === "/Contact_us" && (
        <>
          <div className="Contact_us_inner">
            <p className="Contact_us_title">Contact us</p>
            <p className="Contact_us_titl2">
              <b>PG벤처스</b>와 협업을 원하시거나 프로그램 및 투자 등의
              문의사항이 있으시면 아래로 문의 주시길 바랍니다.
            </p>
            <p className="Contact_us_titl3">문의 유형을 선택주세요.</p>
            <div className="Contact_us_content">
              {formList.map((item, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    name="rodio"
                    id={index}
                    onChange={() => {
                      setContactUsType(item.title);
                    }}
                    checked={item.title === contactUsType ? true : false}
                  />
                  <label htmlFor={index}>
                    <div className="Contact_us_box">
                      <p className="Contact_us_box_title">{item.title}</p>
                      {/* <p className="Contact_us_box_text">- {item.text1}</p>
                      <p className="Contact_us_box_text">- {item.text2}</p>
                      <p className="Contact_us_box_text">- {item.text3}</p>
                      <p className="Contact_us_box_text">- {item.text4}</p>
                      <p className="Contact_us_box_text">- {item.text5}</p> */}
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div className="Contact_us_file">
              {/* <p>&bull;</p>
              <p
                onClick={() => {
                  downloadFile(
                    "http://34.64.112.233:8000/file/SAMPLE.pdf",
                    "사업계획서 양식"
                  );
                }}
              >
                사업계획서 양식 다운로드
              </p> */}
            </div>
            <div className="Contact_us_btn">
              <p
                onClick={() => {
                  if (contactUsType === "") {
                    alert("문의 유형을 선택해주세요.");
                  } else {
                    navigate("/Contact_us/Contact_us_form", {
                      state: {
                        contactUsType: contactUsType,
                      },
                    });
                  }
                }}
              >
                다음
              </p>
            </div>
          </div>
        </>
      )}

      <Routes>
        <Route path="Contact_us_form" element={<Contact_us_form />} />
      </Routes>
    </>
  );
};
export default Contact_us;

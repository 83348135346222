import "./News.css";
import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import Axios from "axios";
const News = () => {
  const [filter, setFilter] = useState("");

  const typeList = ["모집", "공고", "행사", "성과"];

  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    Axios.get("http://34.64.112.233:8000/get/news_form")
      .then((res) => {
        const resData = res.data;
        setNewsList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [page, setPage] = useState(1); //페이지
  const limit = 10; // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset
  const totalPosts =
    newsList.filter((item) => item.TYPE === filter).length === 0
      ? newsList.length
      : newsList.filter((item) => item.TYPE === filter).length;

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="News_inner">
        <p className="News_title">News</p>
        <div className="Portfolio_filter">
          <input
            type="radio"
            name="rodio"
            id="all"
            onClick={() => {
              setFilter("");
            }}
            defaultChecked
          />
          <label htmlFor="all">전체</label>
          {typeList.map((item, index) => (
            <div key={index} className="Portfolio_filter_input">
              <input
                type="radio"
                name="rodio"
                id={item}
                onClick={() => {
                  setFilter(item);
                  setPage(1);
                }}
              />
              <label htmlFor={item}>{item}</label>
            </div>
          ))}
        </div>
        <div className="Home_inner_news_box">
          <table>
            {newsList
              .filter((item) => {
                return item.TYPE.includes(filter);
              })
              .slice(offset, offset + limit)
              .map((item, index) => (
                <tbody key={index}>
                  <tr className="Home_inner_news_top">
                    <td className="Home_inner_news_img" colSpan="2" rowSpan="4">
                      <img
                        src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                        alt="img"
                      />
                    </td>
                    <td className="Home_inner_news_top_td2">
                      {item.HOST_NAME}
                    </td>
                    <td className="Home_inner_news_top_td3">
                      <p>일시&nbsp;&nbsp;</p>
                      <p>{item.START_DATE}</p>
                    </td>
                    <td className="Home_inner_news_top_td4">
                      <p>신청마감일&nbsp;&nbsp;</p>
                      <p>{item.DEADLINE}</p>
                    </td>
                    <td className="Home_inner_news_top_td5">{item.TYPE}</td>
                  </tr>
                  <tr className="Home_inner_news_title">
                    <td colSpan="4">
                      <p
                        onClick={() => {
                          if (item.LINK) {
                            window.open(item.LINK);
                          } else {
                            alert("연결된 링크가 없습니다.");
                          }
                        }}
                        title={item.LINK}
                      >
                        {item.TITLE.length >= 47
                          ? item.TITLE.substring(0, 47) + " . . ."
                          : item.TITLE}
                      </p>
                    </td>
                  </tr>
                  <tr className="Home_inner_news_content">
                    <td colSpan="4">
                      <p
                        onClick={() => {
                          if (item.LINK) {
                            window.open(item.LINK);
                          } else {
                            alert("연결된 링크가 없습니다.");
                          }
                        }}
                        title={item.LINK}
                      >
                        {item.CONTENT.length >= 120
                          ? item.CONTENT.substring(0, 120) + " . . ."
                          : item.CONTENT}
                      </p>
                    </td>
                  </tr>
                  <tr className="Home_inner_news_date_created">
                    <td colSpan="4">{item.C_DATE.slice(0, 14)}(작성일)</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
        <div>
          <Pagination
            activePage={page} // 현재 페이지
            itemsCountPerPage={limit} // 한 페이지랑 보여줄 아이템 갯수
            totalItemsCount={totalPosts} // 총 아이템 갯수
            pageRangeDisplayed={5} // paginator의 페이지 범위
            prevPageText={"‹"} // "이전"을 나타낼 텍스트
            nextPageText={"›"} // "다음"을 나타낼 텍스트
            firstPageText=""
            lastPageText=""
            onChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
          />
        </div>
      </div>
    </>
  );
};
export default News;

import "./About.css";
import React, { useState, useEffect } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import About_form from "./About_form";
import Axios from "axios";
const About = () => {
  const location = useLocation();
  const aboutList = [
    "오픈\n이노베이션",
    "액셀러\n레이팅",
    "전략투자\n컨설팅",
    "스타트업\n투자/육성",
  ];
  const [yearList, setYearList] = useState([]);

  const [historyList, setHistoryList] = useState([]);

  const [teamImg, setTeamImg] = useState([]);

  useEffect(() => {
    // 년도만
    Axios.get("http://34.64.112.233:8000/get/about_history", {
      params: { key: "year" },
    })
      .then((res) => {
        const resData = res.data;
        setYearList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
    // 전체 리스트
    Axios.get("http://34.64.112.233:8000/get/about_history", {
      params: { key: "all" },
    })
      .then((res) => {
        const resData = res.data;
        setHistoryList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
    // 팀 이미지
    Axios.get("http://34.64.112.233:8000/get/Home", {})
      .then((res) => {
        const resData = res.data[0];

        setTeamImg(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // GET요청을 받고 랜더
  if (teamImg.length !== 0) {
    return (
      <>
        {location.pathname === "/About" && (
          <>
            <div className="About_con1">
              <p>WHO WE ARE</p>
              <p>미래를 가장 먼저 만나는 창업의 메카 '판교'</p>
              <p>'판교' 출신 전문가가 모여 시작한 엑셀러레이터</p>
              <img src="./img/Ellipse 11.png" alt="img" />
            </div>
            <div className="About_con2">
              <p className="About_con2_text">
                판교테크노밸리 창업생태계 초기 구축 멤버 및 파트너로 구성된
                PGV가 스타트업과 함께 미래를 만들어갑니다.
              </p>
              <div className="About_con2_circle">
                {aboutList.map((item, index) => (
                  <div key={index} className="About_con2_circle_box">
                    <div className="About_con2_circle_text">
                      <p>{item}</p>
                    </div>
                    {index + 1 <= 3 && (
                      <img src="./img/Vector 8.png" alt="img" />
                    )}
                  </div>
                ))}
              </div>
              <p className="About_con2_bottom_text"></p>
            </div>
            <div className="About_con3">
              <p className="About_con3_text">History</p>
              <img src="./img/Vector 4.png" alt="img" />
              <div className="About_con3_history_content">
                {yearList.map((item1, index) => (
                  <div key={index} className="About_con3_history_box">
                    <p className="About_con3_history_box_year">{item1.YEAR}</p>
                    <div className="About_con3_history_box_text">
                      {historyList.map((item2, index) =>
                        item2.YEAR === item1.YEAR ? (
                          <div key={index}>
                            <p>- {item2.HISTORY}</p>
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="About_con4">
              <p className="About_con4_text">TEAM</p>
              <img
                src={`http://34.64.112.233:8000/file/${teamImg.TEAM_IMG_KEY}`}
                alt="img"
              />
            </div>
            <div className="About_con5">
              <div className="About_con5_box">
                <img src="./img/image 4.png" alt="img" />
                <p>
                  PG 벤처스는 스타트업의 성공적인 비즈니스를 이룰 수 있는
                  전문적인 솔루션을 제공합니다. PG벤처스의 비전과 비즈니스
                  방향성이 더 궁금하시다면 회사소개서를 요청해주세요.
                </p>
              </div>

              <div className="About_con5_bottom_text">
                {/* <NavLink to="/About/About_form">
                  <p>회사 소개서 요청하기</p>
                </NavLink> */}
              </div>
            </div>
          </>
        )}
        <Routes>
          <Route path="About_form" element={<About_form />} />
        </Routes>
      </>
    );
  } else {
    return <div className="Loading">{/* <h2>Loading...</h2> */}</div>;
  }
};
export default About;

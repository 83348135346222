import "./About_form.css";
import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
const About_form = () => {
  const [userEmail, setUserEmail] = useState({
    COM_NAME: "",
    NAME: "",
    TEL: "",
    EMAIL: "",
    CEHCKED: false,
  });
  const onClickSubmit = async (e) => {
    e.preventDefault();
    if (userEmail.CEHCKED === false) {
      alert("개인 정보 동의를 체크해주세요.");
    } else {
      if (window.confirm("요청하시겠습니까?")) {
        await Axios.post("http://34.64.112.233:8000/post/email/about_form", {
          userEmail,
        })
          .then(() => {
            alert("요청이 완료되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const getValue = (e) => {
    const { name, value } = e.target;
    setUserEmail({
      ...userEmail,
      [name]: value,
    });
  };
  return (
    <>
      <div className="About_form">
        <p className="About_form_text">회사소개서 요청하기</p>
        <div className="About_form_title">
          <img src="../img/image 4.png" alt="img" />
          <p>
            PG 벤처스는 스타트업의 성공적인 비즈니스를 이룰 수 있는 전문적인
            솔루션을 제공합니다. PG벤처스의 비전과 비즈니스 방향성이 더
            궁금하시다면 회사소개서를 요청해주세요.
          </p>
        </div>
        <form onSubmit={onClickSubmit}>
          <div className="About_form_box">
            <div className="About_form_box_input">
              <div className="About_form_box_input_box">
                <p>소속명</p>
                <input
                  required
                  type="text"
                  name="COM_NAME"
                  placeholder="소속명을 입력해주세요"
                  autoComplete="off"
                  onChange={getValue}
                />
              </div>
              <div className="About_form_box_input_box">
                <p className="About_form_box_input_margin">연락처</p>
                <input
                  required
                  type="tel"
                  name="TEL"
                  maxLength="13"
                  placeholder="본인 연락처를 입력해주세요( '-' 포함하여 입력)"
                  autoComplete="off"
                  onChange={getValue}
                />
              </div>
            </div>

            <div className="About_form_box_input">
              <div className="About_form_box_input_box">
                <p>성함</p>
                <input
                  required
                  type="text"
                  name="NAME"
                  placeholder="본인 성함을 입력해주세요."
                  autoComplete="off"
                  onChange={getValue}
                />
              </div>
              <div className="About_form_box_input_box">
                <p className="About_form_box_input_margin">이메일</p>
                <input
                  required
                  type="email"
                  name="EMAIL"
                  placeholder="본인 이메일을 입력해주세요."
                  autoComplete="off"
                  onChange={getValue}
                />
              </div>
            </div>
          </div>
          <div className="About_form_Privacy">
            <div className="About_form_Privacy_box">
              <p className="About_form_Privacy_p1">
                피지벤처스에서는 다음과 같이 귀하의 개인정보를 수집 및
                이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여
                주시기 바랍니다.
              </p>
              <p className="About_form_Privacy_p2">
                <b>개인정보 수집, 이용 내역</b>
              </p>
              <table className="About_form_Privacy_table">
                <thead>
                  <tr>
                    <th>수집 목적</th>
                    <th>수집 항목</th>
                    <th>수집 보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>소속기관, 성함, 연락처, 이메일</td>
                    <td>회사소개서 제공을 위한 고객 식별</td>
                    <td>
                      <p>해당서비스 제공기간 및 서비스</p>
                      <p>종료일로부터 1년</p>
                      <p style={{ textDecoration: "underline" }}>
                        단, 업무이력관리정보는 준영구
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="About_form_Privacy_p3">
                ※ 귀하는 위의 개인정보를 수집‧이용에 대한 동의를 거부할 권리가
                있습니다. 그러나 동의를 거부할 경우 요청이 제한됩니다.
              </p>
            </div>
          </div>
          <div className="Program_form_bottom">
            <div className="Program_form_bottom_box">
              <p className="Program_form_bottom_box_text">
                개인 정보 동의하시겠습니까? (필수)
              </p>
              <div className="Program_form_check_box">
                <p className="Program_form_check_box_text">동의</p>
                <input
                  type="radio"
                  id="ok1"
                  name="CEHCKED"
                  onChange={() => {
                    setUserEmail({
                      ...userEmail,
                      CEHCKED: true,
                    });
                  }}
                />
                <label htmlFor="ok1" />
                <p className="Program_form_check_box_text">비동의</p>
                <input
                  type="radio"
                  id="no1"
                  name="CEHCKED"
                  onChange={() => {
                    setUserEmail({
                      ...userEmail,
                      CEHCKED: false,
                    });
                  }}
                />
                <label htmlFor="no1" />
              </div>
            </div>
          </div>

          <div className="About_form_submit">
            <p>
              {/* 영업일 기준 0~0일 이내로 적어주신 이메일 통해 연락드리겠습니다. */}
              빠른 시일 내에 적어주신 이메일을 통해 연락드리겠습니다.
            </p>
            <input type="submit" value="요청하기" />
          </div>
        </form>
      </div>
    </>
  );
};
export default About_form;

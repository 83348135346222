import "./Admin_About.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import imageCompression from "browser-image-compression";
import Admin_table from "./Admin_table";

const Admin_News = () => {
  // 등록 모달창
  const [addModal, setAddModal] = useState(false);
  // 저장할 뉴스
  const [addNews_form, setAddNews_form] = useState({
    HOST_NAME: "",
    START_DATE: "",
    DEADLINE: "",
    TYPE: "",
    TITLE: "",
    CONTENT: "",
    LINK: "",
    SHOW_YN: "Y",
  });
  // 저장할 뉴스 이미지
  const [addNewsImg, setAddNewsImg] = useState();

  const onClickAdd = async () => {
    if (
      addNews_form.HOST_NAME === "" ||
      addNews_form.START_DATE === "" ||
      addNews_form.DEADLINE === "" ||
      addNews_form.TYPE === "" ||
      addNews_form.TITLE === "" ||
      addNews_form.CONTENT === "" ||
      !addNewsImg
    ) {
      alert("내용이 작성되지 않았습니다.");
    } else {
      if (window.confirm("등록하시겠습니까?")) {
        const formDate = new FormData();
        formDate.append("file", addNewsImg ? addNewsImg : "");
        formDate.append("fileName", addNewsImg ? addNewsImg.name : "");
        formDate.append("news_form", JSON.stringify(addNews_form));
        await Axios.post("http://34.64.112.233:8000/post/news_form", formDate)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const getValue = (e) => {
    const { name, value } = e.target;
    setAddNews_form({
      ...addNews_form,
      [name]: value,
    });
  };

  // 이미지 resize
  const handleFileOnChange = async (e) => {
    let file = e.target.files[0]; // 입력받은 file객체

    // 이미지 옵션 설정
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 500,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setAddNewsImg(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="Admin_inner">
        <p className="Admin_table_title">뉴스</p>
        <div className="Admin_add_btn">
          <button
            onClick={() => {
              setAddModal(true);
            }}
          >
            등록
          </button>
        </div>
        <Admin_table table_name="News_form" />
      </div>
      {addModal && (
        <div className="Admin_edit_modal">
          <div className="Admin_edit_modal_inner_top">
            <p>등록</p>
            <img
              src="../img/pg_close.png"
              alt="img"
              onClick={() => {
                setAddModal(false);
              }}
            />
          </div>
          <div className="Admin_edit_modal_inner_form">
            <div className="Admin_edit_modal_inner_form_input">
              <input type="file" id="news_img" onChange={handleFileOnChange} />
              <p
                className="Admin_edit_modal_inner_form_input_title"
                // title="1920 x 782 px"
              >
                이미지
              </p>
              <label htmlFor="news_img">
                <p>{addNewsImg ? addNewsImg.name : ""}</p>
              </label>
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">기관명</p>
              <input
                type="text"
                name="HOST_NAME"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">일시</p>
              <input
                type="text"
                name="START_DATE"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">마감일</p>
              <input
                type="text"
                name="DEADLINE"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">분류</p>
              <input
                type="text"
                name="TYPE"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">제목</p>
              <input
                type="text"
                name="TITLE"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">내용</p>
              <textarea name="CONTENT" onChange={getValue} />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">링크</p>
              <input
                type="text"
                name="LINK"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">
                표시여부
              </p>
              <select name="SHOW_YN" onChange={getValue}>
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </div>
          </div>
          <div className="Admin_edit_modal_inner_btn">
            <button
              onClick={() => {
                setAddModal(false);
              }}
            >
              취소
            </button>
            <button
              onClick={() => {
                onClickAdd("addForm");
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default Admin_News;

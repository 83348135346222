import "./Admin_Home.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
const Admin_Home = () => {
  const [editModal, setEditModal] = useState(false);
  const [teamEditModal, setTeamEditModal] = useState(false);
  // 전체 리스트
  const [HomeList, setHomeList] = useState([]);
  // 메인 이미지
  const [mainImg, setMainImg] = useState();
  // 팀 이미지
  const [teamImg, setTeamImg] = useState();
  // 메인,서브 텍스트

  // console.log(HomeList);
  // 리스트 가져오기
  useEffect(() => {
    Axios.get("http://34.64.112.233:8000/get/Home")
      .then((res) => {
        const resData = res.data[0];
        setHomeList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getValue = (e) => {
    const { name, value } = e.target;
    setHomeList({
      ...HomeList,
      [name]: value,
    });
  };

  // 수정 확인
  const onClickEdit = async (key) => {
    if (window.confirm("수정하시겠습니까?")) {
      if (key === "main") {
        const formDate = new FormData();
        formDate.append("file", mainImg);
        formDate.append("fileName", mainImg.name);
        // formDate.append("file", teamImg);
        // formDate.append("fileName", teamImg.name);
        formDate.append("key", "main");
        formDate.append("HomeList", JSON.stringify(HomeList));
        await Axios.put("http://34.64.112.233:8000/put/Home", formDate)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (key === "team") {
        const formDate = new FormData();
        formDate.append("file", teamImg);
        formDate.append("fileName", teamImg.name);
        formDate.append("key", "team");
        formDate.append("HomeList", JSON.stringify(HomeList));
        await Axios.put("http://34.64.112.233:8000/put/Home", formDate)
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // GET요청을 받고 랜더
  if (HomeList.length !== 0) {
    return (
      <>
        <div className="Admin_inner">
          <div className="Admin_edit_btn">
            <button
              onClick={() => {
                setEditModal(true);
                // 이미지 이름
                setMainImg({ name: HomeList.IMG });
              }}
            >
              수정
            </button>
          </div>
          <div className="Admin_Home_main">
            <img
              src={`http://34.64.112.233:8000/file/${HomeList.IMG_KEY}`}
              alt="img"
            />
            <div className="Admin_Home_main_text">
              <p>{HomeList.M_TEXT}</p>
              <p>{HomeList.S_TEXT}</p>
            </div>
          </div>
          <p className="Admin_table_title">팀</p>
          <div className="Admin_edit_btn">
            <button
              onClick={() => {
                setTeamEditModal(true);
                setTeamImg({ name: HomeList.TEAM_IMG });
              }}
            >
              수정
            </button>
          </div>
          <div className="Admin_Home_team">
            <img
              src={`http://34.64.112.233:8000/file/${HomeList.TEAM_IMG_KEY}`}
              alt="img"
            />
          </div>
        </div>

        {editModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setEditModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <input
                  type="file"
                  id="main_file"
                  onChange={(e) => {
                    setMainImg(e.target.files[0]);
                  }}
                />
                <p
                  className="Admin_edit_modal_inner_form_input_title"
                  title="1920 x 782 px"
                >
                  메인 이미지
                </p>
                <label htmlFor="main_file">
                  <p>{mainImg ? mainImg.name : HomeList.IMG}</p>
                </label>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  텍스트1
                </p>
                <input
                  type="text"
                  name="M_TEXT"
                  value={HomeList.M_TEXT}
                  onChange={getValue}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  텍스트2
                </p>
                <input
                  type="text"
                  name="S_TEXT"
                  value={HomeList.S_TEXT}
                  onChange={getValue}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={HomeList.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setEditModal(false);
                }}
              >
                취소
              </button>
              <button
                onClick={() => {
                  onClickEdit("main");
                }}
              >
                확인
              </button>
            </div>
          </div>
        )}

        {teamEditModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setTeamEditModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <input
                  type="file"
                  id="team_file"
                  onChange={(e) => {
                    setTeamImg(e.target.files[0]);
                  }}
                />
                <p
                  className="Admin_edit_modal_inner_form_input_title"
                  title="1920 x 0 px"
                >
                  팀 이미지
                </p>
                <label htmlFor="team_file">
                  <p>{teamImg ? teamImg.name : HomeList.TEAM_IMG}</p>
                </label>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={HomeList.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setTeamEditModal(false);
                }}
              >
                취소
              </button>
              <button
                onClick={() => {
                  onClickEdit("team");
                }}
              >
                확인
              </button>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <div className="Loading">{/* <h2>Loading...</h2> */}</div>;
  }
};
export default Admin_Home;

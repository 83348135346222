import "./Admin_About.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import Admin_table from "./Admin_table";

const Admin_About = () => {
  // 등록 모달창
  const [addModal, setAddModal] = useState(false);
  // 등록 리스트
  const [addHistoryList, setAddHistoryList] = useState({
    YEAR: "",
    HISTORY: "",
  });

  const getValue = (e) => {
    const { name, value } = e.target;
    setAddHistoryList({
      ...addHistoryList,
      [name]: value,
    });
  };

  // 등록 확인
  const onClickAdd = async () => {
    if (addHistoryList.YEAR === "" || addHistoryList.HISTORY === "") {
      alert("내용이 작성되지 않았습니다.");
    } else {
      if (window.confirm("등록하시겠습니까?")) {
        await Axios.post("http://34.64.112.233:8000/post/about_history", {
          addHistoryList,
        })
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <div className="Admin_inner">
        <p className="Admin_table_title">History</p>
        <div className="Admin_add_btn">
          <button
            onClick={() => {
              setAddModal(true);
            }}
          >
            등록
          </button>
        </div>
        <Admin_table table_name="About_history" />

        <p className="Admin_table_title">회사소개서 요청 내역</p>
        <Admin_table table_name="About_form" />
      </div>

      {addModal && (
        <div className="Admin_edit_modal">
          <div className="Admin_edit_modal_inner_top">
            <p>등록</p>
            <img
              src="../img/pg_close.png"
              alt="img"
              onClick={() => {
                setAddModal(false);
              }}
            />
          </div>
          <div className="Admin_edit_modal_inner_form">
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">년도</p>
              <input
                type="text"
                name="YEAR"
                onChange={getValue}
                autoComplete="off"
                maxLength={4}
              />
            </div>
            <div className="Admin_edit_modal_inner_form_input">
              <p className="Admin_edit_modal_inner_form_input_title">이력</p>
              <input
                type="text"
                name="HISTORY"
                onChange={getValue}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="Admin_edit_modal_inner_btn">
            <button
              onClick={() => {
                setAddModal(false);
              }}
            >
              취소
            </button>
            <button onClick={onClickAdd}>확인</button>
          </div>
        </div>
      )}
    </>
  );
};
export default Admin_About;

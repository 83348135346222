import { Routes, Route } from "react-router-dom";
import NotFound from "../NotFound";

import Home from "../Pages/Home";
import About from "../Pages/About";
import Program from "../Pages/Program";
import Space from "../Pages/Space";
import Portfolio from "../Pages/Portfolio";
import Partners from "../Pages/Partners";
import News from "../Pages/News";
import Contact_us from "../Pages/Contact_us";

// 관리자 페이지
import Login from "../Login";
import Admin_Home from "../AdminPages/Admin_Home";
import Admin_About from "../AdminPages/Admin_About";
import Admin_Program from "../AdminPages/Admin_Program";
import Admin_Space from "../AdminPages/Admin_Space";
import Admin_Portfolio from "../AdminPages/Admin_Portfolio";
import Admin_Partners from "../AdminPages/Admin_Partners";
import Admin_News from "../AdminPages/Admin_News";
import Admin_Contact_us_form from "../AdminPages/Admin_Contact_us_form";
const RouterPages = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/About/*" element={<About />} />
        <Route path="/Program/*" element={<Program />} />
        {/* <Route path="/Space/*" element={<Space />} /> */}
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Partners" element={<Partners />} />
        <Route path="/News" element={<News />} />
        <Route path="/Contact_us/*" element={<Contact_us />} />

        {/* 관리자 페이지 */}
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/Home" element={<Admin_Home />} />
        <Route path="/admin/About" element={<Admin_About />} />
        <Route path="/admin/Program" element={<Admin_Program />} />
        <Route path="/admin/Space" element={<Admin_Space />} />
        <Route path="/admin/Portfolio" element={<Admin_Portfolio />} />
        <Route path="/admin/Partners" element={<Admin_Partners />} />
        <Route path="/admin/News" element={<Admin_News />} />
        <Route path="/admin/Contact_us" element={<Admin_Contact_us_form />} />
      </Routes>
    </>
  );
};
export default RouterPages;

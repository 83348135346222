import "./Program.css";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Program_form from "./Program_form";
const Program = () => {
  const location = useLocation();

  const slideRef1 = useRef();
  const slideRef2 = useRef();
  const slideRef3 = useRef();

  // 현재 화면에 보여지는 슬라이드의 번호
  const [currentSlide1, setCurrentSlide1] = useState(1);
  const [currentSlide2, setCurrentSlide2] = useState(1);
  const [currentSlide3, setCurrentSlide3] = useState(1);
  // 원본 배치프로그램
  const programList1 = [
    {
      title: "스타트업 분석",
      sub_title:
        "- 스타트업의 현황, BM, 시장성 등을 기업 stage에 따라 진단/분석하여\n  효과적인 성장 전략을 모색",
    },
    {
      title: "성장코디네이션",
      sub_title:
        "- 20년 이상의 경력을 지닌 분야 별 C-level급 리더들이 스타트업의 성장을\n  가속화 할 수 있는 효과적인 비즈니스 전략을 설계 자금조달 전략부터\n  경영, 기술개발, 투자유치까지 성장에 필요한 전반적인 비즈니스\n  로드맵을 제시",
    },
    {
      title: "1:1 밀착 관리",
      sub_title:
        "- 전담 매니저 배정을 통해 기업의 안정적인 성장 도모\n- 성장 로드맵에 진단에 따른 맞춤형 지원(마케팅, MVP개선)\n- KPI 설정 및 추적 관리\n- 정부지원사업계획서(PSST) 작성 지원\n- 피칭 덱 제작 및 피칭 훈련 지원\n- 매주 1회 정기 오피스아워 진행",
    },
    {
      title: "전문가 자문",
      sub_title:
        "- 특허/법률/세무회계/노무인사/마케팅/투자/시장조사/글로벌 진출 분야별\n  전문가 자문 및 네트워킹 지원\n- 보육기간 중 기업별 2회 이상 지원\n- 후속 관리",
    },
    {
      title: "개발 서포터 매칭",
      sub_title:
        "- 기업 별 필요에 따른 전문 인력 매칭 지원\n- UX/UI, 디자인, S/W, 기술개발 분야 지원",
    },
    {
      title: "PG inner circle",
      sub_title:
        "- PGV패밀리 만의  협업 생태계 구축. 협업 시너지 효과를 촉진하여 \n  기업 간의 동반 성장을 도모\n- 기업 간의 성공적인 비즈니스를 운영하기 위한 정보, 전략, 지원 그리고\n  네트워크를 공유하는 비즈니스 지원 프로그램",
    },
  ];
  // 원본 OI 트레이닝
  const programList2 = [
    {
      title: "Skill set",
      sub_title: "- 수요조사\n- 매칭 전략 수립\n- 전담 매니저 배정\n- 사전준비",
      img: "./img/pro2_1.png",
    },
    {
      title: "PG Meet up",
      sub_title: "- 대/중견기업 매칭\n- 협업 기회 확보",
      img: "./img/pro2_2.png",
    },
    {
      title: "PoC",
      sub_title: "- PoC 검증 사업화 연계 가속화\n- 다수 협업 채널 보유",
      img: "./img/pro2_3.png",
    },
  ];
  // 원본 투자
  const programList3 = [
    {
      title: "투자유치",
      sub_title:
        "- IR Deck 제작 및 피칭스킬 훈련, 모의 IR Test 등 밸류업 액션부터\n  내부 직간접 투자(Seed) 및 외부 파트너사와의 네트워크\n  활용 투자연계 진행\n- 벤처투자조합 최대 124억  개인투자조합 최대 34억 글로벌투자조합 \n  최대 60억",
    },
  ];

  // 복사본 배치프로그램
  let [copyProgramList1, setCopyProgramList1] = useState([]);

  // 복사본 OI 트레이닝
  let [copyProgramList2, setCopyProgramList2] = useState([]);

  // 복사본 투자
  let [copyProgramList3, setCopyProgramList3] = useState([]);

  // 무한 슬라이드를 만들기 위한 원본배열에서 맨앞 맨뒤 값복사
  useEffect(() => {
    // 배치프로그램
    if (programList1) {
      const first = programList1[0];
      const last = programList1[programList1.length - 1];
      setCopyProgramList1([last, ...programList1, first]);
    }

    // OI 트레이닝
    if (programList2) {
      const first = programList2[0];
      const last = programList2[programList2.length - 1];
      setCopyProgramList2([last, ...programList2, first]);
    }
    // 투자
    if (programList3) {
      const first = programList3[0];
      const last = programList3[programList3.length - 1];
      setCopyProgramList3([last, ...programList3, first]);
    }
  }, []);

  let style1 = {
    transform: `translateX(${currentSlide1 * -785}px)`,
    transition: "all 500ms",
  };

  let style2 = {
    transform: `translateX(${currentSlide2 * -785}px)`,
    transition: "all 500ms",
  };

  let style3 = {
    transform: `translateX(${currentSlide3 * -785}px)`,
    transition: "all 500ms",
  };

  const onClickHandler = (num, type) => {
    // 배치프로그램
    if (type === "slide1") {
      let index = currentSlide1 + num;
      setCurrentSlide1(index);
      if (index <= 0) {
        setTimeout(() => {
          slideRef1.current.style.transition = "none";
          setCurrentSlide1(programList1.length);
        }, 500);
      } else if (index >= programList1.length + 1) {
        index -= programList1.length;
        setTimeout(() => {
          slideRef1.current.style.transition = "none";
          setCurrentSlide1(index);
        }, 500);
      }
      slideRef1.current.style.transition = "all 500ms";
    }
    // OI 프로그램
    else if (type === "slide2") {
      let index = currentSlide2 + num;
      setCurrentSlide2(index);
      if (index <= 0) {
        setTimeout(() => {
          slideRef2.current.style.transition = "none";
          setCurrentSlide2(programList2.length);
        }, 500);
      } else if (index >= programList2.length + 1) {
        index -= programList2.length;
        setTimeout(() => {
          slideRef2.current.style.transition = "none";
          setCurrentSlide2(index);
        }, 500);
      }
      slideRef2.current.style.transition = "all 500ms";
    }
    // 투자
    else if (type === "slide3") {
      let index = currentSlide3 + num;
      setCurrentSlide3(index);
      if (index <= 0) {
        setTimeout(() => {
          slideRef3.current.style.transition = "none";
          setCurrentSlide3(programList3.length);
        }, 500);
      } else if (index >= programList3.length + 1) {
        index -= programList3.length;
        setTimeout(() => {
          slideRef3.current.style.transition = "none";
          setCurrentSlide3(index);
        }, 500);
      }
      slideRef3.current.style.transition = "all 500ms";
    }
  };

  // 사업계획서 양식
  const downloadFile = (url, fileName) => {
    fetch(url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 10000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };

  return (
    <>
      {location.pathname === "/Program" && (
        <>
          <div className="Program_con1">
            <p>TO BUILD YOUR FUTURE</p>
            {/* <p>미라클 10 프로그램의 강점 한줄 요약 쓰기</p>
            <p>PGV 미라클 10 프로그램</p> */}
            <p>
              <b>PGV는 Partnership 기반</b>으로 스타트업의 일원이 되어
              <b> 성장 가속화를 위한 3가지 프로그램</b>을 진행합니다.
            </p>
            <div className="Program_con1_btn">
              {/* <p
                onClick={() => {
                  downloadFile(
                    "http://34.64.112.233:8000/file/SAMPLE.pdf",
                    "사업계획서 양식"
                  );
                }}
              >
                사업계획서 양식 다운로드
              </p> */}
              <NavLink to="/Program/Program_form">
                <p>프로그램 신청하기</p>
              </NavLink>
            </div>
          </div>
          <div className="Program_con2">
            {/* 배치프로그램 */}
            <div className="Program_con2_box">
              <div className="Program_con2_box_text">
                <div className="Program_con2_box_title">
                  <img src="../img/Rectangle 118.png" alt="img" />
                  <p>Miracle 10</p>
                </div>
                <div className="Program_con2_box_detail_text">
                  <p>예비/초기 기업을 대상으로 BM개발부터 시장</p>
                  <p>진입까지 All-in-One으로 밀착 지원하는</p>
                  <p>비즈니스 성공률이 높은 10개월 과정의</p>
                  <p>컴퍼니빌드형 액셀러레이팅 프로그램</p>
                </div>
                <p className="Program_con2_box_detail_text_bottom">
                  - 2022년 실적 : 16개사 완료(경콘진 공공연계: 8개사, 성남OI:
                  8개사)
                </p>
              </div>
              {/* 슬라이드 */}
              <div className="Program_con2_box_slide">
                <div
                  className="Program_con2_box_slide_box"
                  ref={slideRef1}
                  style={style1}
                >
                  {copyProgramList1.map((item, index) => (
                    <div
                      key={index}
                      className="Program_con2_box_slide_box_content"
                    >
                      <p className="Program_con2_box_slide_box_content_title">
                        {item.title}
                      </p>
                      <p className="Program_con2_box_slide_box_content_sub_title">
                        {item.sub_title}
                      </p>
                    </div>
                  ))}
                </div>
                {programList1.length <= 1 ? (
                  ""
                ) : (
                  <>
                    <div className="Program_con2_box_slide_arrow_btn">
                      <img
                        src="./img/left.png"
                        alt="img"
                        onClick={() => {
                          currentSlide1 !== 0 && onClickHandler(-1, "slide1");
                        }}
                      />
                      <img
                        src="./img/right.png"
                        alt="img"
                        onClick={() => {
                          currentSlide1 !== programList1.length + 1 &&
                            onClickHandler(1, "slide1");
                        }}
                      />
                    </div>
                    <div className="Program_con2_box_slide_dot_btn">
                      {Array(programList1.length)
                        .fill()
                        .map((_, i) => (
                          <div
                            key={i}
                            className={
                              currentSlide1 === i + 1 ? "dot_on" : "dot_no"
                            }
                            onClick={() => {
                              slideRef1.current.style.transition = "all 500ms";
                              setCurrentSlide1(i + 1);
                            }}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* OI 프로그램 */}
            <div className="Program_con2_box">
              {/* 슬라이드 */}
              <div className="Program_con2_box_slide">
                <div
                  className="Program_con2_box_slide_box"
                  ref={slideRef2}
                  style={style2}
                >
                  {copyProgramList2.map((item, index) => (
                    <div
                      key={index}
                      className="Program_con2_box_slide_box_content"
                    >
                      <p className="Program_con2_box_slide_box_content_title">
                        {item.title}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="Program_con2_box_slide_box_content_sub_title">
                          {item.sub_title}
                        </p>
                        <img src={item.img} />
                      </div>
                    </div>
                  ))}
                </div>
                {programList2.length <= 1 ? (
                  ""
                ) : (
                  <>
                    <div className="Program_con2_box_slide_arrow_btn">
                      <img
                        src="./img/left.png"
                        alt="img"
                        onClick={() => {
                          currentSlide2 !== 0 && onClickHandler(-1, "slide2");
                        }}
                      />
                      <img
                        src="./img/right.png"
                        alt="img"
                        onClick={() => {
                          currentSlide2 !== programList2.length + 1 &&
                            onClickHandler(1, "slide2");
                        }}
                      />
                    </div>
                    <div className="Program_con2_box_slide_dot_btn">
                      {Array(programList2.length)
                        .fill()
                        .map((_, i) => (
                          <div
                            key={i}
                            className={
                              currentSlide2 === i + 1 ? "dot_on" : "dot_no"
                            }
                            onClick={() => {
                              slideRef2.current.style.transition = "all 500ms";
                              setCurrentSlide2(i + 1);
                            }}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="Program_con2_box_text">
                <div className="Program_con2_box_title Program_con2_box_con2_text">
                  <img src="../img/Rectangle 118.png" alt="img" />
                  <p>Open innovation 24</p>
                </div>
                <div className="Program_con2_box_detail_text Program_con2_box_con2_detail">
                  <p>24개월 후 기업의 성장 목표를 설정하여</p>
                  <p>대/중견 기업과의 사업제휴, 투자, M&A를</p>
                  <p>도모하는 오픈이노베이션 프로그램</p>
                </div>
              </div>
            </div>
            {/* 투자 */}
            <div className="Program_con2_box">
              <div className="Program_con2_box_text">
                <div className="Program_con2_box_title">
                  <img src="../img/Rectangle 118.png" alt="img" />
                  <p>Invest 72</p>
                </div>
                <div className="Program_con2_box_detail_text">
                  <p>72개월 과정의</p>
                  <p>맞춤형 밸류 업 투자 유치 프로그램</p>
                </div>
              </div>
              {/* 슬라이드 */}
              <div className="Program_con2_box_slide">
                <div
                  className="Program_con2_box_slide_box"
                  ref={slideRef3}
                  style={style3}
                >
                  {copyProgramList3.map((item, index) => (
                    <div
                      key={index}
                      className="Program_con2_box_slide_box_content"
                    >
                      <p className="Program_con2_box_slide_box_content_title">
                        {item.title}
                      </p>
                      <p className="Program_con2_box_slide_box_content_sub_title">
                        {item.sub_title}
                      </p>
                    </div>
                  ))}
                </div>
                {programList3.length <= 1 ? (
                  ""
                ) : (
                  <>
                    <div className="Program_con2_box_slide_arrow_btn">
                      <img
                        src="./img/left.png"
                        alt="img"
                        onClick={() => {
                          currentSlide3 !== 0 && onClickHandler(-1, "slide3");
                        }}
                      />
                      <img
                        src="./img/right.png"
                        alt="img"
                        onClick={() => {
                          currentSlide3 !== programList3.length + 1 &&
                            onClickHandler(1, "slide3");
                        }}
                      />
                    </div>
                    <div className="Program_con2_box_slide_dot_btn">
                      {Array(programList3.length)
                        .fill()
                        .map((_, i) => (
                          <div
                            key={i}
                            className={
                              currentSlide3 === i + 1 ? "dot_on" : "dot_no"
                            }
                            onClick={() => {
                              slideRef3.current.style.transition = "all 500ms";
                              setCurrentSlide3(i + 1);
                            }}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="Program_con3">
            <p>PG벤처스와 함께 새로운 혁신을 만들어 갈 스타트업을 찾습니다!</p>
            <p>
              PG벤처스는 혁신적인 아이디어와 열정으로 세상을 변화시키고자 하는
              스타트업을 지원합니다. 변화에 동참하여 함께 성장해 나아갈 분들은
              언제든지 연락주세요.
            </p>
            <NavLink to="/Program/Program_form">
              <p>프로그램 신청하기</p>
            </NavLink>
          </div>
        </>
      )}

      <Routes>
        <Route path="Program_form" element={<Program_form />} />
      </Routes>
    </>
  );
};
export default Program;

import "./Admin_About.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import Admin_table from "./Admin_table";

const Admin_Program = () => {
  return (
    <>
      <div className="Admin_inner">
        <p className="Admin_table_title">프로그램</p>

        <Admin_table table_name="Program_form" />
      </div>
    </>
  );
};
export default Admin_Program;

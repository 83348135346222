import "./Contact_us_form.css";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
const Contact_us_form = () => {
  const location = useLocation();

  const contactUsType = location.state.contactUsType;
  const [userEmail, setUserEmail] = useState({
    PRO_NUM:
      contactUsType === "스타트업\n액설레이팅 제안"
        ? "4"
        : contactUsType === "회사/기관\n제휴 문의"
        ? "5"
        : "6",
    COM_NAME: "",
    NAME: "",
    TEL: "",
    EMAIL: "",
    CONTENT: "",
    CEHCKED: false,
  });
  const [file, setFile] = useState("");
  const onChangeFile = (e) => {
    let file = e.target.files[0] === undefined ? "" : e.target.files[0];
    setFile(file);
  };
  const getValue = (e) => {
    const { name, value } = e.target;
    setUserEmail({
      ...userEmail,
      [name]: value,
    });
  };
  const onClickSubmit = async (e) => {
    e.preventDefault();
    if (file === "") {
      alert("사업계획서를 업로드해주세요.");
    } else if (userEmail.CEHCKED === false) {
      alert("개인 정보 동의를 체크해주세요.");
    } else {
      if (window.confirm("문의 요청을 하시겠습니까?")) {
        const formDate = new FormData();
        formDate.append("file", file);
        formDate.append("fileName", file.name);
        formDate.append("userEmail", JSON.stringify(userEmail));
        await Axios.post(
          "http://34.64.112.233:8000/post/email/program_contactUs",
          formDate
        )
          .then(() => {
            alert("문의요청이 완료되었습니다.");
            window.location.href = "/Contact_us";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  return (
    <>
      <div className="Contact_us_form_inner">
        <p className="Contact_us_form_title">Contact us</p>
        <p className="Contact_us_form_title2">{contactUsType}</p>
        <form onSubmit={onClickSubmit}>
          <div className="About_form_box">
            <div className="About_form_box_input">
              <p>기업명</p>
              <input
                required
                type="text"
                name="COM_NAME"
                placeholder="기업명을 입력해주세요"
                autoComplete="off"
                onChange={getValue}
              />
              <p className="About_form_box_input_margin">연락처</p>
              <input
                required
                type="tel"
                name="TEL"
                placeholder="본인 연락처를 입력해주세요 ( '-' 포함하여 입력)"
                autoComplete="off"
                onChange={getValue}
              />
            </div>
            <div className="About_form_box_input">
              <p>성함</p>
              <input
                required
                type="text"
                name="NAME"
                placeholder="본인 성함을 입력해주세요."
                autoComplete="off"
                onChange={getValue}
              />
              <p className="About_form_box_input_margin">이메일</p>
              <input
                required
                type="email"
                name="EMAIL"
                placeholder="본인 이메일을 입력해주세요."
                autoComplete="off"
                onChange={getValue}
              />
            </div>
          </div>
          <p className="Program_form_item">아이템 요약(500자 이내)</p>
          <textarea
            required
            maxLength="499"
            name="CONTENT"
            placeholder="자사의 아이템을 500자 이내로 요약해서 작성해주세요"
            autoComplete="off"
            onChange={getValue}
          />
          <div className="Program_form_file">
            <input type="file" id="file" onChange={onChangeFile} />
            <p className="Program_form_file_title">사업계획서</p>
            <p className="Program_form_file_name">{file ? file.name : ""}</p>
            <label htmlFor="file">
              <p className="Program_form_file_upload">업로드</p>
            </label>
          </div>
          <div className="About_form_Privacy">
            <div className="About_form_Privacy_box">
              <p className="About_form_Privacy_p1">
                피지벤처스에서는 다음과 같이 귀하의 개인정보를 수집 및
                이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여
                주시기 바랍니다.
              </p>
              <p className="About_form_Privacy_p2">
                <b>개인정보 수집, 이용 내역</b>
              </p>
              <table className="About_form_Privacy_table">
                <thead>
                  <tr>
                    <th>수집 목적</th>
                    <th>수집 항목</th>
                    <th>수집 보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>소속기관, 성함, 연락처, 이메일, 사업계획서</td>
                    <td>제휴를 관리하는 고객 관리</td>
                    <td>
                      <p>해당서비스 제공기간 및 서비스</p>
                      <p>종료일로부터 1년</p>
                      <p style={{ textDecoration: "underline" }}>
                        단, 업무이력관리정보는 준영구
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="About_form_Privacy_p3">
                ※ 귀하는 위의 개인정보를 수집‧이용에 대한 동의를 거부할 권리가
                있습니다. 그러나 동의를 거부할 경우 요청이 제한됩니다.
              </p>
            </div>
          </div>
          <div className="Program_form_bottom">
            <div className="Program_form_bottom_box">
              <p className="Program_form_bottom_box_text">
                개인 정보 동의하시겠습니까? (필수)
              </p>
              <div className="Program_form_check_box">
                <p className="Program_form_check_box_text">동의</p>
                <input
                  type="radio"
                  id="ok1"
                  name="CEHCKED"
                  onChange={() => {
                    setUserEmail({
                      ...userEmail,
                      CEHCKED: true,
                    });
                  }}
                />
                <label htmlFor="ok1" />
                <p className="Program_form_check_box_text">비동의</p>
                <input
                  type="radio"
                  id="no1"
                  name="CEHCKED"
                  onChange={() => {
                    setUserEmail({
                      ...userEmail,
                      CEHCKED: false,
                    });
                  }}
                />
                <label htmlFor="no1" />
              </div>
            </div>
          </div>
          <div className="Program_form_submit">
            <input type="submit" value="요청하기" />
          </div>
        </form>
      </div>
    </>
  );
};
export default Contact_us_form;

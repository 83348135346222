import "./Portfolio.css";
import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import Axios from "axios";
import Img from "react-webp-image";
const Portfolio = () => {
  const [serachText, setSerachText] = useState("");

  const [filter, setFilter] = useState("");

  const [typeList, setTypeList] = useState([]);

  const [portfolioList, setPortfolioList] = useState([]);

  useEffect(() => {
    // 기수만
    Axios.get("http://34.64.112.233:8000/get/portfolio_form", {
      params: { key: "type" },
    })
      .then((res) => {
        const resData = res.data;

        setTypeList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
    // 전체 리스트
    Axios.get("http://34.64.112.233:8000/get/portfolio_form", {
      params: { key: "all" },
    })
      .then((res) => {
        const resData = res.data;
        setPortfolioList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [page, setPage] = useState(1); //페이지
  const limit = 9; // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset

  const totalPosts = () => {
    if (serachText === "") {
      const length =
        portfolioList.filter((item) => item.TYPE === filter).length === 0
          ? portfolioList.length
          : portfolioList.filter((item) => item.TYPE === filter).length;
      return length;
    } else if (filter === "") {
      const length =
        portfolioList.filter((item) =>
          item.COM_NAME.toUpperCase().includes(serachText.toUpperCase())
        ).length === 0
          ? 1
          : portfolioList.filter((item) =>
              item.COM_NAME.toUpperCase().includes(serachText.toUpperCase())
            ).length;
      return length;
    } else {
      const length =
        portfolioList.filter(
          (item) =>
            item.TYPE === filter &&
            item.COM_NAME.toUpperCase().includes(serachText.toUpperCase())
        ).length === 0
          ? 1
          : portfolioList.filter(
              (item) =>
                item.TYPE === filter &&
                item.COM_NAME.toUpperCase().includes(serachText.toUpperCase())
            ).length;
      return length;
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  if (typeList.length !== 0) {
    return (
      <>
        <div className="Portfolio_inner">
          <p className="Portfolio_title">Portfolio</p>
          <div className="Portfolio_serach_bar">
            <input
              type="text"
              placeholder="회사명을 입력해주세요."
              onChange={(e) => {
                setPage(1);
                setSerachText(e.target.value);
              }}
            />
            <img src="./img/serach.png" alt="img" />
          </div>
          <div className="Portfolio_filter">
            <input
              type="radio"
              name="rodio"
              id="all"
              onClick={() => {
                setFilter("");
              }}
              defaultChecked
            />
            <label htmlFor="all">전체</label>
            {typeList.map((item, index) => (
              <div key={index} className="Portfolio_filter_input">
                <input
                  type="radio"
                  name="rodio"
                  id={item.TYPE}
                  onClick={() => {
                    setFilter(item.TYPE);
                    setPage(1);
                  }}
                />
                <label htmlFor={item.TYPE}>{item.TYPE}</label>
              </div>
            ))}
          </div>
          <div className="Portfolio_content">
            {portfolioList
              .filter((item) => {
                return (
                  item.COM_NAME.toUpperCase().includes(
                    serachText.toUpperCase()
                  ) && item.TYPE.includes(filter)
                );
              })
              .slice(offset, offset + limit)
              .map((item, index) => (
                <div key={index} className="Portfolio_box">
                  <p className="Portfolio_box_type">{item.TYPE}</p>
                  <div className="Portfolio_box_img">
                    <img
                      src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                      alt="img"
                    />
                  </div>
                  <div className="Portfolio_box_text">
                    <p>{item.COM_NAME}</p>
                    <p>{item.NAME}</p>
                    <p>{item.CONTENT.slice(0, 80)}</p>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <Pagination
              activePage={page} // 현재 페이지
              itemsCountPerPage={limit} // 한 페이지랑 보여줄 아이템 갯수
              totalItemsCount={totalPosts()} // 총 아이템 갯수
              pageRangeDisplayed={5} // paginator의 페이지 범위
              prevPageText={"‹"} // "이전"을 나타낼 텍스트
              nextPageText={"›"} // "다음"을 나타낼 텍스트
              firstPageText=""
              lastPageText=""
              onChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
            />
          </div>
        </div>
      </>
    );
  } else {
    return <div className="Loading">{/* <h2>Loading...</h2> */}</div>;
  }
};
export default Portfolio;

import "./App.css";
import { NavLink } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="not_Found_page">
      <h2>페이지를 찾을 수 없습니다.</h2>
      <div>
        <NavLink to="/">
          <h3>홈으로 이동</h3>
        </NavLink>
      </div>
    </div>
  );
};
export default NotFound;

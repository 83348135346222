import "./Admin_Header.css";
import { NavLink, Link } from "react-router-dom";
const Admin_Header = () => {
  const navList = [
    {
      title: "HOME",
      link: "/admin/Home",
    },
    {
      title: "ABOUT",
      link: "/admin/About",
    },
    {
      title: "PROGRAM",
      link: "/admin/Program",
    },
    {
      title: "SPACE",
      link: "/admin/Space",
    },
    {
      title: "PORTFOLIO",
      link: "/admin/Portfolio",
    },
    {
      title: "PARTNERS",
      link: "/admin/Partners",
    },
    {
      title: "NEWS",
      link: "/admin/News",
    },
    {
      title: "CONTACT US",
      link: "/admin/Contact_us",
    },
  ];
  return (
    <>
      <div className="Admin_Header">
        <img src="../img/피지로고_화이트 1.png" alt="img" />
        {navList.map((item, index) => (
          <div key={index} className="Admin_Header_nav">
            <NavLink to={item.link}>
              <p>{item.title}</p>
            </NavLink>
          </div>
        ))}

        <p
          className="Admin_Header_Logout"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Logout
        </p>
      </div>
    </>
  );
};
export default Admin_Header;

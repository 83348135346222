import "./Footer.css";
const Footer = () => {
  return (
    <>
      <div className="Footer_inner">
        <div className="Footer_inner_text">
          <div>
            <p>PG VENTURES</p>
          </div>
          <div>
            <p>주소</p>
            <p>경기도 성남시 수정구 달래내로 46</p>
            <p>성남글로벌융합센터 B동 105호</p>
          </div>
          <div>
            <p>사업자등록 번호</p>
            <p>894-86-02673</p>
          </div>
        </div>
        <div className="Footer_inner_copy">
          COPYRIGHT (C) PGVENTURES. All Rights reserved
        </div>
      </div>
    </>
  );
};
export default Footer;

import "./Home.css";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
const Home = () => {
  // DB에 저장된 이미지, 텍스트 리스트
  const [HomeList, setHomeList] = useState([]);

  useEffect(() => {
    // Main
    Axios.get("http://34.64.112.233:8000/get/Home")
      .then((res) => {
        const resData = res.data[0];
        setHomeList(resData);
      })
      .catch((err) => {
        console.log("Main : ", err);
      });
    // News
    Axios.get("http://34.64.112.233:8000/get/news_form")
      .then((res) => {
        const resData = res.data.slice(0, 5);
        setNewsList(resData);
      })
      .catch((err) => {
        console.log("News : ", err);
      });
  }, []);

  const programList = [
    {
      sub_title: "성장",
      title: "Miralcle 10",
      text: "초기 안정적인 자금확보를 위한\n사업화 전력 및 KPI 설정 등\n10개월 고정의 밀착\n컴퍼니빌드 프로그램",
      date: "10개월",
      target: "예비 3년 미만 초기 기업",
    },
    {
      sub_title: "네트워크",
      title: "Open innovation 24",
      text: "오픈이노베이션 준비 단계부터\n1:1 협업 미팅 제공 및 실증\nTest부터 PoC 검증 등 24개월 과정의\n매출 다각화 프로그램",
      date: "최대 24개월",
      target: "3년 미만 초기 기업",
    },
    {
      sub_title: "투자",
      title: "Invest 72",
      text: "72개월 과정의\n맞춤형 밸류 업 투자 프로그램",
      date: "최대 72개월",
      target: "3년 미만 초기 기업",
    },
  ];

  const [newsList, setNewsList] = useState([]);

  // GET요청을 받고 랜더
  if (HomeList.length !== 0) {
    return (
      <>
        <div className="Home_inner">
          <div className="Home_inner_main">
            <img
              src={`http://34.64.112.233:8000/file/${HomeList.IMG_KEY}`}
              alt="img"
            />
            <div className="Home_inner_main_text">
              <p>{HomeList.M_TEXT}</p>
              <p>{HomeList.S_TEXT}</p>
            </div>
          </div>
          <div className="inner_sub">
            <div className="Home_inner_sub_text">
              <p>PGV는 안정적인 자금 확보와 밀착 지원을 기본으로 한</p>
              <p>컴퍼니빌더형 액셀러레이팅 프로그램 통해</p>
              <p>스타트업의 성장 기반을 완성 합니다.</p>
            </div>
            <div className="Home_inner_program_click">
              <NavLink to="/Program">
                <p>더보기 ＞</p>
              </NavLink>
            </div>
            <div className="Home_inner_program">
              {programList.map((item, index) => (
                <div key={index} className="Home_inner_program_box">
                  <div className="Home_inner_program_main">
                    <div className="Home_inner_program_box_sub_title">
                      <p>{item.sub_title}</p>
                    </div>
                    <div className="Home_inner_program_box_title">
                      <p>{item.title}</p>
                    </div>

                    <div className="Home_inner_program_box_text">
                      <p>{item.text}</p>
                    </div>
                  </div>
                  <div className="Home_inner_program_sub">
                    <div className="Home_inner_program_box_sub">
                      <p>활동 기간</p>
                      <p>{item.date}</p>
                    </div>
                  </div>
                  <div className="Home_inner_program_sub">
                    <div className="Home_inner_program_box_sub">
                      <p>대상</p>
                      <p>{item.target}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="Home_inner_sub_news">
            <div className="inner_sub">
              <div className="Home_inner_sub_text2">
                <p>
                  <span>PGV는 스타업과</span>
                </p>
                <p>
                  <span>함께 고민하는 </span>
                  <span className="Home_inner_sub_text2_color">파트너 </span>
                  <span>입니다.</span>
                </p>
              </div>

              <div className="Home_inner_program_click">
                <NavLink to="/News">
                  <p>더보기 ＞</p>
                </NavLink>
              </div>

              <div className="Home_inner_news_box">
                <table>
                  {newsList.map((item, index) => (
                    <tbody key={index}>
                      <tr className="Home_inner_news_top">
                        <td
                          className="Home_inner_news_img"
                          colSpan="2"
                          rowSpan="4"
                        >
                          <img
                            src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                            alt="img"
                          />
                        </td>
                        <td className="Home_inner_news_top_td2">
                          {item.HOST_NAME}
                        </td>
                        <td className="Home_inner_news_top_td3">
                          <p>일시&nbsp;&nbsp;</p>
                          <p>{item.START_DATE}</p>
                        </td>
                        <td className="Home_inner_news_top_td4">
                          <p>신청마감일&nbsp;&nbsp;</p>
                          <p>{item.DEADLINE}</p>
                        </td>
                        <td className="Home_inner_news_top_td5">{item.TYPE}</td>
                      </tr>
                      <tr className="Home_inner_news_title">
                        <td colSpan="4">
                          <p
                            onClick={() => {
                              if (item.LINK) {
                                window.open(item.LINK);
                              } else {
                                alert("연결된 링크가 없습니다.");
                              }
                            }}
                            title={item.LINK}
                          >
                            {item.TITLE.length >= 47
                              ? item.TITLE.substring(0, 47) + " . . ."
                              : item.TITLE}
                          </p>
                        </td>
                      </tr>
                      <tr className="Home_inner_news_content">
                        <td colSpan="4">
                          <p
                            onClick={() => {
                              if (item.LINK) {
                                window.open(item.LINK);
                              } else {
                                alert("연결된 링크가 없습니다.");
                              }
                            }}
                            title={item.LINK}
                          >
                            {item.CONTENT.length >= 120
                              ? item.CONTENT.substring(0, 120) + " . . ."
                              : item.CONTENT}
                          </p>
                        </td>
                      </tr>
                      <tr className="Home_inner_news_date_created">
                        <td colSpan="4">{item.C_DATE.slice(0, 14)}(작성일)</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <div className="Home_inner_footer_img">
            <img src="./img/technology-7111796_1920 1.png" alt="img" />
            <div className="Home_inner_footer_text">
              <p>PG벤처스는 언제나 열려 있습니다.</p>
              <p>저희와 함께 할 분들의 연락을 기다립니다.</p>
              <div className="Home_inner_footer_btn">
                <NavLink to="/Contact_us">
                  <p>제안하기</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div className="Loading">{/* <h2>Loading...</h2> */}</div>;
  }
};
export default Home;

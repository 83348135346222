import "./Login.css";
import React, { useState } from "react";
const Login = () => {
  const [admin, setAdmin] = useState({ id: "", password: "" });

  const onClickLogin = () => {
    if (admin.id === "admin" && admin.password === "admin") {
      window.location.href = "/admin/Home";
    } else {
      alert("아이디와 비밀번호를 다시 확인해주세요");
    }
  };

  const getValue = (e) => {
    const { name, value } = e.target;
    setAdmin({
      ...admin,
      [name]: value,
    });
  };
  return (
    <>
      <div className="Login_inner">
        <div className="Login_box">
          <img src="/img/image 4.png" alt="img" />
          <div className="Login_input1">
            <input
              name="id"
              type="text"
              onChange={getValue}
              autoComplete="off"
              placeholder="ID"
            />
          </div>
          <div>
            <input
              name="password"
              type="password"
              onChange={getValue}
              autoComplete="off"
              placeholder="Password"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onClickLogin();
                }
              }}
            />
          </div>

          <button className="Login_btn" onClick={onClickLogin}>
            Sign In
          </button>
        </div>
      </div>
    </>
  );
};
export default Login;

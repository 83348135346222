import "./Admin_table.css";
import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import Axios from "axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
const Admin_table = ({ table_name }) => {
  // 테이블 헤더
  const [tableHeader, setTableHeader] = useState([]);

  // 테이블 리스트
  const [tableList, setTableList] = useState([]);
  // 순서 변경 시
  const [orderChange, setOrderChange] = useState(0);
  // 테이블 헤더 설정 및 해당 리스트 가져오기
  useEffect(() => {
    // About_history
    if (table_name === "About_history") {
      setTableHeader([
        {
          INDEX: "번호",
          YEAR: "년도",
          HISTORY: "이력",
          DATE: "수정일",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // About_form
    else if (table_name === "About_form") {
      setTableHeader([
        {
          INDEX: "번호",
          COM_NAME: "소속명",
          NAME: "이름",
          TEL: "연락처",
          EMAIL: "이메일",
          NOTE: "비고",
          DATE: "제출일",
          EDIT: "수정",
        },
      ]);
    }
    // Program_form
    else if (table_name === "Program_form") {
      setTableHeader([
        {
          INDEX: "번호",
          PRO_NUM: "유형",
          EMAIL: "이메일",
          COM_NAME: "소속명",
          NAME: "이름",
          TEL: "연락처",
          CONTENT: "내용",
          NOTE: "비고",
          FILE: "사업계획서",
          DATE: "등록일",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // Space_User
    else if (table_name === "Space_User") {
      setTableHeader([
        {
          INDEX: "번호",
          USER_ID: "아이디",
          NAME: "대표자명",
          EMAIL: "이메일",
          PHONE: "연락처",
          COMPANY: "소속명",
          USE_YN: "사용여부",
          REG_DT: "수정일",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // Space_Place_form
    else if (table_name === "Space_Place_form") {
      setTableHeader([
        {
          INDEX: "번호",
          LOC_CATE: "장소",
          LOC_NAME: "좌석명",
          LOC_IMG: "이미지",
          USE_YN: "사용유무",
          DATE: "수정일",
          ORDER: "순서",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // Space_Place_Reservation
    else if (table_name === "Space_Place_Reservation") {
      setTableHeader([
        {
          INDEX: "번호",
          USER_ID: "아이디",
          NAME: "대표자명",
          COMPANY: "소속명",
          LOC_CATE: "장소명",
          LOC_NAME: "좌석명",
          DATE: "예약일자",
          TIME: "예약시간",
          RES_NAME: "예약자명",
          PHONE: "연락처",
          CHECKOUT_TIME: "퇴실시간",
          STATUS: "상태",
          REG_DT: "등록일",
        },
      ]);
    }
    // Portfolio_type_list
    else if (table_name === "Portfolio_type_list") {
      setTableHeader([
        {
          TYPE: "기수명",
          ORDER: "순서",
          EDIT: "수정",
        },
      ]);
    }
    // Portfolio_form
    else if (table_name === "Portfolio_form") {
      setTableHeader([
        {
          INDEX: "번호",
          TYPE_ID: "기수명",
          IMG: "이미지",
          COM_NAME: "기업명",
          NAME: "대표자명",
          CONTENT: "소개글",
          SHOW_YN: "표시여부",
          DATE: "수정일",
          ORDER: "순서",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // Partners_type_list
    else if (table_name === "Partners_type_list") {
      setTableHeader([
        {
          TYPE: "기관명",
          ORDER: "순서",
          EDIT: "수정",
        },
      ]);
    }
    // Partners_form
    else if (table_name === "Partners_form") {
      setTableHeader([
        {
          INDEX: "번호",
          TYPE_ID: "기관명",
          IMG: "이미지",
          SHOW_YN: "표시여부",
          DATE: "수정일",
          ORDER: "순서",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // News_form
    else if (table_name === "News_form") {
      setTableHeader([
        {
          INDEX: "번호",
          IMG: "이미지",
          HOST_NAME: "기관명",
          START_DATE: "일시",
          DEADLINE: "마감일",
          TYPE: "분류",
          TITLE: "제목",
          CONTENT: "내용",
          LINK: "링크",
          SHOW_YN: "표시여부",
          DATE: "수정일",
          EDIT: "수정 / 삭제",
        },
      ]);
    }
    // Contact_us_form
    else if (table_name === "Contact_us_form") {
      setTableHeader([
        {
          INDEX: "번호",
          PRO_NUM: "유형",
          EMAIL: "이메일",
          COM_NAME: "소속명",
          NAME: "이름",
          TEL: "연락처",
          CONTENT: "내용",
          NOTE: "비고",
          FILE: "사업계획서",
          DATE: "등록일",
          EDIT: "수정 / 삭제",
        },
      ]);
    }

    // DB에 저장된 기수명, 기관명 리스트 가져오기
    if (table_name === "Portfolio_form") {
      Axios.get("http://34.64.112.233:8000/get/protfolio_type")
        .then((res) => {
          const resData = res.data;
          setGetTypeList(resData);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (table_name === "Partners_form") {
      Axios.get("http://34.64.112.233:8000/get/partners_type")
        .then((res) => {
          const resData = res.data;
          setGetTypeList(resData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // 해당 테이블 리스트 가져오기
    Axios.get("http://34.64.112.233:8000/get/table_List", {
      params: { table_name: table_name },
    })
      .then((res) => {
        const resData = res.data;
        setTableList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderChange]);

  //   테이블 정렬
  const [order, setOrder] = useState("ASC");
  const handleOrder = (key) => {
    if (order === "ASC") {
      const sorted = [...tableList].sort((a, b) => (a[key] > b[key] ? 1 : -1));
      setOrder(order);
      setTableList(sorted);
      setOrder("DESC");
    } else if (order === "DESC") {
      const sorted = [...tableList].sort((a, b) => (a[key] < b[key] ? 1 : -1));
      setOrder(!order);
      setTableList(sorted);
      setOrder("ASC");
    }
  };

  // ----------↓↓↓ 페이지 네이션 ↓↓↓----------

  const [page, setPage] = useState(1); //페이지
  const [limit, setLimit] = useState(10); // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset
  const totalPosts = tableList.length;

  const handlePageChange = (page) => {
    setPage(page);
  };
  // ----------↑↑↑ 페이지 네이션 ↑↑↑----------

  // ----------↓↓↓ 수정 모달창 ↓↓↓----------
  // About_history
  const [about_historyModal, setAbout_historyModal] = useState(false);

  // About_form
  const [about_formModal, setAbout_formModal] = useState(false);

  // Program_form
  const [program_formModal, setProgram_formModal] = useState(false);

  // Space_User
  const [space_user_modal, setSpace_user_modal] = useState(false);

  // Space_Place_form
  const [space_Place_formModal, setSpace_Place_formModal] = useState(false);

  // Portfolio_type_list
  const [portfolio_typeModal, setPortfolio_typeModal] = useState(false);

  // Portfolio_form
  const [portfolio_formModal, setPortfolio_formModal] = useState(false);

  // Partners_type_list
  const [partners_typeModal, setPartners_typeModal] = useState(false);

  // Partners_form
  const [partners_formModal, setPartners_formModal] = useState(false);

  // News_form
  const [news_formModal, setNews_formModal] = useState(false);

  // ----------↑↑↑ 수정 모달창 ↑↑↑----------

  // ----------↓↓↓ 수정 변수 ↓↓↓----------
  // About_history
  const [about_historyList, setAbout_historyList] = useState([]);

  // About_form
  const [about_form, setAbout_form] = useState([]);

  // Program_form
  const [program_form, setProgram_form] = useState([]);

  // Space_User
  const [space_user, setSpace_user] = useState([]);

  // Space_Place_form
  const [space_Place_form, setSpace_Place_form] = useState([]);

  // Space_Place_form(img)
  const [space_Place_formImg, setSpace_Place_formImg] = useState();

  // Portfolio_type_list
  const [portfolio_type, setPortfolio_type] = useState([]);

  // Portfolio_form
  const [portfolio_form, setPortfolio_form] = useState([]);

  // Portfolio_form(img)
  const [portfolio_formImg, setPortfolio_formImg] = useState();

  // Portfolio_type_list
  const [partners_type, setPartners_type] = useState([]);

  // Partners_form
  const [partners_form, setPartners_form] = useState([]);

  // Partners_form(img)
  const [partners_formImg, setPartners_formImg] = useState();

  // News_form
  const [news_form, setNews_form] = useState([]);

  // News_form(img)
  const [newsformImg, setNewsformImg] = useState();

  // ----------↑↑↑ 수정 변수 ↑↑↑----------
  // 수정 확인
  const onClickEdit = async () => {
    if (window.confirm("수정하시겠습니까?")) {
      if (table_name === "About_history") {
        await Axios.put("http://34.64.112.233:8000/put/about_history", {
          about_historyList,
        })
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "About_form") {
        await Axios.put("http://34.64.112.233:8000/put/about_form", {
          about_form,
        })
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (
        table_name === "Program_form" ||
        table_name === "Contact_us_form"
      ) {
        await Axios.put(
          "http://34.64.112.233:8000/put/program_contact_us_form",
          {
            program_form,
          }
        )
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "Space_Place_form") {
        const formDate = new FormData();
        formDate.append("file", space_Place_formImg ? space_Place_formImg : "");
        formDate.append(
          "fileName",
          space_Place_formImg ? space_Place_formImg.name : ""
        );
        formDate.append("space_Place_form", JSON.stringify(space_Place_form));
        await Axios.put("http://34.64.112.233:8000/put/space_place", formDate)
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "Space_User") {
        await Axios.put("http://34.64.112.233:8000/put/space_user", {
          space_user,
        })
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "Portfolio_type_list") {
        await Axios.put("http://34.64.112.233:8000/put/protfolio_type", {
          portfolio_type,
        })
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "Portfolio_form") {
        const formDate = new FormData();
        formDate.append("file", portfolio_formImg ? portfolio_formImg : "");
        formDate.append(
          "fileName",
          portfolio_formImg ? portfolio_formImg.name : ""
        );
        formDate.append("portfolio_form", JSON.stringify(portfolio_form));
        await Axios.put(
          "http://34.64.112.233:8000/put/protfolio_form",
          formDate
        )
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "Partners_type_list") {
        await Axios.put("http://34.64.112.233:8000/put/partners_type", {
          partners_type,
        })
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "Partners_form") {
        const formDate = new FormData();
        formDate.append("file", partners_formImg ? partners_formImg : "");
        formDate.append(
          "fileName",
          partners_formImg ? partners_formImg.name : ""
        );
        formDate.append("partners_form", JSON.stringify(partners_form));
        await Axios.put("http://34.64.112.233:8000/put/partners_form", formDate)
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      } else if (table_name === "News_form") {
        const formDate = new FormData();
        formDate.append("file", newsformImg ? newsformImg : "");
        formDate.append("fileName", newsformImg ? newsformImg.name : "");
        formDate.append("news_form", JSON.stringify(news_form));
        await Axios.put("http://34.64.112.233:8000/put/news_form", formDate)
          .then(() => {
            alert("수정되었습니다.");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            alert("수정을 실패하였습니다.");
          });
      }
    }
  };
  // 삭제 확인
  const onClickDelete = async (item) => {
    if (window.confirm("삭제하시겠습니까?")) {
      await Axios.delete("http://34.64.112.233:8000/delete/table_List", {
        data: {
          item,
          table_name,
        },
      })
        .then(() => {
          alert("삭제가 완료되었습니다.");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          alert("삭제를 실패하였습니다.");
        });
    }
  };

  // Program, Contact us 파일 삭제
  const onClickFileDelete = async (item) => {
    if (window.confirm("사업계획서를 삭제하시겠습니까?")) {
      await Axios.put(
        "http://34.64.112.233:8000/put/program_contact_us_form_file",
        {
          program_form: item,
        }
      )
        .then(() => {
          alert("삭제가 완료되었습니다.");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          alert("삭제를 실패하였습니다.");
        });
    }
  };
  // DB에 저장된 기수명, 기관명 리스트
  const [getTypeList, setGetTypeList] = useState([]);

  // 사업계획서 다운로드
  const downloadFile = (url, fileName) => {
    console.log(url, fileName);
    fetch(url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 10000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };

  const orderCnt = async (key, item) => {
    await Axios.put("http://34.64.112.233:8000/put/table_order", {
      table_name,
      key,
      item,
    })
      .then(() => {
        setOrderChange(orderChange + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // GET요청을 받고 랜더
  if (tableList.length !== 0) {
    return (
      <>
        <div className="table_top">
          <div className="table_show_item">
            <input
              type="number"
              value={limit}
              onChange={(e) => {
                setLimit(parseInt(e.target.value, 10));
              }}
            />
            <p>Show item</p>
          </div>
        </div>

        <TableContainer align="center" component={Paper}>
          <Table stickyHeader>
            {/* 테이블 헤더 */}
            <TableHead>
              {tableHeader.map((item, index) =>
                //
                table_name === "About_history" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("YEAR");
                      }}
                    >
                      {item.YEAR}
                    </TableCell>
                    <TableCell>{item.HISTORY}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "About_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.TEL}</TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.NOTE}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Program_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.PRO_NUM}</TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.TEL}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell>{item.FILE}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.NOTE}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Space_Place_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.LOC_CATE}</TableCell>
                    <TableCell>{item.LOC_NAME}</TableCell>
                    <TableCell>{item.LOC_IMG}</TableCell>
                    <TableCell>{item.USE_YN}</TableCell>

                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.ORDER}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Space_User" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.USER_ID}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.PHONE}</TableCell>
                    <TableCell>{item.COMPANY}</TableCell>
                    <TableCell>{item.USE_YN}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("REG_DT");
                      }}
                    >
                      {item.REG_DT}
                    </TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Space_Place_Reservation" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.USER_ID}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.COMPANY}</TableCell>
                    <TableCell>{item.LOC_CATE}</TableCell>
                    <TableCell>{item.LOC_NAME}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell>{item.TIME}</TableCell>
                    <TableCell>{item.RES_NAME}</TableCell>
                    <TableCell>{item.PHONE}</TableCell>
                    <TableCell>{item.CHECKOUT_TIME}</TableCell>
                    <TableCell>{item.STATUS}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("REG_DT");
                      }}
                    >
                      {item.REG_DT}
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Portfolio_type_list" ? (
                  <TableRow key={index}>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell>{item.ORDER}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Portfolio_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.TYPE_ID}</TableCell>
                    <TableCell>{item.IMG}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell>{item.SHOW_YN}</TableCell>

                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.ORDER}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Partners_type_list" ? (
                  <TableRow key={index}>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell>{item.ORDER}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Partners_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.TYPE_ID}</TableCell>
                    <TableCell>{item.IMG}</TableCell>
                    <TableCell>{item.SHOW_YN}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.ORDER}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "News_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.IMG}</TableCell>
                    <TableCell>{item.HOST_NAME}</TableCell>
                    <TableCell>{item.START_DATE}</TableCell>
                    <TableCell>{item.DEADLINE}</TableCell>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell>{item.TITLE}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell>{item.LINK}</TableCell>
                    <TableCell>{item.SHOW_YN}</TableCell>

                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>

                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Contact_us_form" ? (
                  <TableRow key={index}>
                    <TableCell>{item.INDEX}</TableCell>
                    <TableCell>{item.PRO_NUM}</TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.TEL}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell>{item.FILE}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOrder("DATE");
                      }}
                    >
                      {item.DATE}
                    </TableCell>
                    <TableCell>{item.NOTE}</TableCell>
                    <TableCell>{item.EDIT}</TableCell>
                  </TableRow>
                ) : (
                  <h3>table_name_header err</h3>
                )
              )}
            </TableHead>
            {/* 테이블 바디 */}
            <TableBody>
              {tableList.slice(offset, offset + limit).map((item, index) =>
                //
                table_name === "About_history" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.YEAR}</TableCell>
                    <TableCell>{item.HISTORY}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setAbout_historyList(item);
                          setAbout_historyModal(true);
                        }}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => {
                          onClickDelete(item);
                        }}
                      >
                        삭제
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "About_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.TEL}</TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell>{item.NOTE}</TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setAbout_form(item);
                          setAbout_formModal(true);
                        }}
                      >
                        수정
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Program_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {item.PRO_NUM === "1"
                        ? "Miracle 10"
                        : item.PRO_NUM === "2"
                        ? "Open innovation 24"
                        : "Invest 72"}
                    </TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.TEL}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        item.FILE !== "" &&
                          downloadFile(
                            `http://34.64.112.233:8000/file/${item.FILE_KEY}`,
                            item.FILE
                          );
                      }}
                    >
                      {item.FILE === "" ? "없음" : "있음"}
                    </TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell>{item.NOTE}</TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setProgram_form(item);
                          setProgram_formModal(true);
                        }}
                      >
                        수정
                      </button>
                      {item.FILE === "" ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            onClickFileDelete(item);
                          }}
                        >
                          삭제
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Space_Place_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.LOC_CATE}</TableCell>
                    <TableCell>{item.LOC_NAME}</TableCell>
                    <TableCell className="table_img">
                      <img
                        src={`http://34.64.112.233:8000/file/${item.LOC_IMG_KEY}`}
                        alt="img"
                      />
                    </TableCell>

                    <TableCell>{item.USE_YN}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell className="table_order_btn">
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT > 1) {
                            orderCnt("up", item);
                          } else {
                            alert("제일 첫번째 게시글입니다.");
                          }
                        }}
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT < tableList.length) {
                            orderCnt("down", item);
                          } else {
                            alert("제일 마지막 게시글입니다.");
                          }
                        }}
                      >
                        ↓
                      </button>
                    </TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setSpace_Place_form(item);
                          setSpace_Place_formImg({ name: item.LOC_IMG });
                          setSpace_Place_formModal(true);
                        }}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => {
                          onClickDelete(item);
                        }}
                      >
                        삭제
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Space_User" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.USER_ID}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.PHONE}</TableCell>
                    <TableCell>{item.COMPANY}</TableCell>
                    <TableCell>{item.USER_STATE}</TableCell>
                    <TableCell>{item.REG_DT}</TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setSpace_user(item);
                          setSpace_user_modal(true);
                        }}
                      >
                        수정
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Space_Place_Reservation" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.USER_ID}</TableCell>

                    <TableCell>{item.NAME}</TableCell>

                    <TableCell>{item.COMPANY}</TableCell>

                    <TableCell>{item.LOC_CATE}</TableCell>

                    <TableCell>{item.LOC_NAME}</TableCell>

                    <TableCell>{item.DATE}</TableCell>

                    <TableCell>
                      {item.START_TIME.slice(-5)}~{item.END_TIME.slice(-5)}
                    </TableCell>

                    <TableCell>{item.RES_NAME}</TableCell>

                    <TableCell>{item.PHONE}</TableCell>

                    <TableCell>
                      {item.CHECKOUT_TIME
                        ? item.CHECKOUT_TIME.slice(-5)
                        : item.CHECKOUT_TIME}
                    </TableCell>

                    <TableCell>{item.STATUS}</TableCell>

                    <TableCell>{item.REG_DT}</TableCell>
                  </TableRow>
                ) : //
                table_name === "Portfolio_type_list" ? (
                  <TableRow key={index}>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell className="table_order_btn">
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT > 1) {
                            orderCnt("up", item);
                          } else {
                            alert("제일 첫번째 게시글입니다.");
                          }
                        }}
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT < tableList.length) {
                            orderCnt("down", item);
                          } else {
                            alert("제일 마지막 게시글입니다.");
                          }
                        }}
                      >
                        ↓
                      </button>
                    </TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setPortfolio_type(item);
                          setPortfolio_typeModal(true);
                        }}
                      >
                        수정
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Portfolio_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell className="table_img">
                      <img
                        src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                        alt="img"
                      />
                    </TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell>{item.SHOW_YN}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell className="table_order_btn">
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT > 1) {
                            orderCnt("up", item);
                          } else {
                            alert("제일 첫번째 게시글입니다.");
                          }
                        }}
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT < tableList.length) {
                            orderCnt("down", item);
                          } else {
                            alert("제일 마지막 게시글입니다.");
                          }
                        }}
                      >
                        ↓
                      </button>
                    </TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setPortfolio_form(item);
                          setPortfolio_formImg({ name: item.IMG });
                          setPortfolio_formModal(true);
                        }}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => {
                          onClickDelete(item);
                        }}
                      >
                        삭제
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Partners_type_list" ? (
                  <TableRow key={index}>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell className="table_order_btn">
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT > 1) {
                            orderCnt("up", item);
                          } else {
                            alert("제일 첫번째 게시글입니다.");
                          }
                        }}
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT < tableList.length) {
                            orderCnt("down", item);
                          } else {
                            alert("제일 마지막 게시글입니다.");
                          }
                        }}
                      >
                        ↓
                      </button>
                    </TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setPartners_type(item);
                          setPartners_typeModal(true);
                        }}
                      >
                        수정
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Partners_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell className="table_img">
                      <img
                        src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                        alt="img"
                      />
                    </TableCell>
                    <TableCell>{item.SHOW_YN}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell className="table_order_btn">
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT > 1) {
                            orderCnt("up", item);
                          } else {
                            alert("제일 첫번째 게시글입니다.");
                          }
                        }}
                      >
                        ↑
                      </button>
                      <button
                        onClick={() => {
                          if (item.ORDER_CNT < tableList.length) {
                            orderCnt("down", item);
                          } else {
                            alert("제일 마지막 게시글입니다.");
                          }
                        }}
                      >
                        ↓
                      </button>
                    </TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setPartners_form(item);
                          setPartners_formImg({ name: item.IMG });
                          setPartners_formModal(true);
                        }}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => {
                          onClickDelete(item);
                        }}
                      >
                        삭제
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "News_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell className="table_img">
                      <img
                        src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                        alt="img"
                      />
                    </TableCell>
                    <TableCell>{item.HOST_NAME}</TableCell>
                    <TableCell>{item.START_DATE}</TableCell>
                    <TableCell>{item.DEADLINE}</TableCell>
                    <TableCell>{item.TYPE}</TableCell>
                    <TableCell>{item.TITLE}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>

                    <TableCell
                      style={{ color: "#1E90FF", cursor: "pointer" }}
                      onClick={() => {
                        if (item.LINK) {
                          window.open(item.LINK);
                        }
                      }}
                      title={item.LINK}
                    >
                      {item.LINK ? "링크" : "없음"}
                    </TableCell>

                    <TableCell>{item.SHOW_YN}</TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setNews_form(item);
                          setNewsformImg({ name: item.IMG });
                          setNews_formModal(true);
                        }}
                      >
                        수정
                      </button>
                      <button
                        onClick={() => {
                          onClickDelete(item);
                        }}
                      >
                        삭제
                      </button>
                    </TableCell>
                  </TableRow>
                ) : //
                table_name === "Contact_us_form" ? (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {item.PRO_NUM === "4"
                        ? "스타트업 액설레이팅 제안"
                        : item.PRO_NUM === "5"
                        ? "회사/기관 제휴 문의"
                        : "일반/기타 문의"}
                    </TableCell>
                    <TableCell>{item.EMAIL}</TableCell>
                    <TableCell>{item.COM_NAME}</TableCell>
                    <TableCell>{item.NAME}</TableCell>
                    <TableCell>{item.TEL}</TableCell>
                    <TableCell>{item.CONTENT}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        item.FILE !== "" &&
                          downloadFile(
                            `http://34.64.112.233:8000/file/${item.FILE_KEY}`,
                            item.FILE
                          );
                      }}
                    >
                      {item.FILE === "" ? "없음" : "있음"}
                    </TableCell>
                    <TableCell>{item.DATE}</TableCell>
                    <TableCell>{item.NOTE}</TableCell>
                    <TableCell className="table_edit_btn">
                      <button
                        onClick={() => {
                          setProgram_form(item);
                          setProgram_formModal(true);
                        }}
                      >
                        수정
                      </button>
                      {item.FILE === "" ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            onClickFileDelete(item);
                          }}
                        >
                          삭제
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <h3>table_name err</h3>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div>
          <Pagination
            activePage={page} // 현재 페이지
            itemsCountPerPage={limit} // 한 페이지랑 보여줄 아이템 갯수
            totalItemsCount={totalPosts} // 총 아이템 갯수
            pageRangeDisplayed={5} // paginator의 페이지 범위
            prevPageText={"‹"} // "이전"을 나타낼 텍스트
            nextPageText={"›"} // "다음"을 나타낼 텍스트
            firstPageText=""
            lastPageText=""
            onChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
          />
        </div>

        {about_historyModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setAbout_historyModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">년도</p>
                <input
                  type="text"
                  name="YEAR"
                  value={about_historyList.YEAR}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setAbout_historyList({
                      ...about_historyList,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                  maxLength={4}
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">이력</p>
                <input
                  type="text"
                  name="HISTORY"
                  value={about_historyList.HISTORY}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setAbout_historyList({
                      ...about_historyList,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={about_historyList.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setAbout_historyModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {about_formModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setAbout_formModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  소속명
                </p>
                <input
                  type="text"
                  value={about_form.COM_NAME}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  담당자명
                </p>
                <input
                  type="text"
                  value={about_form.NAME}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  연락처
                </p>
                <input
                  type="text"
                  value={about_form.TEL}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  이메일
                </p>
                <input
                  type="text"
                  value={about_form.EMAIL}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  제출일
                </p>
                <input
                  type="text"
                  value={about_form.DATE}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">비고</p>
                <input
                  type="text"
                  name="NOTE"
                  value={about_form.NOTE ? about_form.NOTE : ""}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setAbout_form({
                      ...about_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setAbout_formModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {program_formModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setProgram_formModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">유형</p>
                <input
                  type="text"
                  value={
                    program_form.PRO_NUM === "1"
                      ? "Miracle 10"
                      : program_form.PRO_NUM === "2"
                      ? "Open innovation 24"
                      : program_form.PRO_NUM === "3"
                      ? "Invest 72"
                      : program_form.PRO_NUM === "4"
                      ? "스타트업 액설레이팅 제안"
                      : program_form.PRO_NUM === "5"
                      ? "회사/기관 제휴 문의"
                      : "일반/기타 문의"
                  }
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  이메일
                </p>
                <input
                  type="text"
                  value={program_form.EMAIL}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  소속명
                </p>
                <input
                  type="text"
                  value={program_form.COM_NAME}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">이름</p>
                <input
                  type="text"
                  value={program_form.NAME}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  연락처
                </p>
                <input
                  type="text"
                  value={program_form.TEL}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">내용</p>
                <input
                  type="text"
                  value={program_form.CONTENT}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  사업계획서
                </p>
                <input
                  type="text"
                  value={program_form.FILE}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  등록일
                </p>
                <input
                  type="text"
                  value={program_form.DATE}
                  autoComplete="off"
                  disabled
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">비고</p>
                <input
                  type="text"
                  name="NOTE"
                  value={program_form.NOTE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setProgram_form({
                      ...program_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setProgram_formModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {space_Place_formModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setSpace_Place_formModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">장소</p>
                <input
                  type="text"
                  name="LOC_CATE"
                  value={space_Place_form.LOC_CATE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_Place_form({
                      ...space_Place_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>

              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  좌석명
                </p>
                <input
                  type="text"
                  name="LOC_NAME"
                  value={space_Place_form.LOC_NAME}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_Place_form({
                      ...space_Place_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>

              <div className="Admin_edit_modal_inner_form_input">
                <input
                  type="file"
                  id="portfolio_form_img"
                  onChange={(e) => {
                    setSpace_Place_formImg(e.target.files[0]);
                  }}
                />
                <p className="Admin_edit_modal_inner_form_input_title">
                  이미지
                </p>
                <label htmlFor="portfolio_form_img">
                  <p>{space_Place_formImg ? space_Place_formImg.name : ""}</p>
                </label>
              </div>
              <div className="Admin_edit_modal_img">
                <img
                  src={`http://34.64.112.233:8000/file/${space_Place_form.LOC_IMG_KEY}`}
                  alt="img"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  사용유무
                </p>
                <select
                  name="USE_YN"
                  value={space_Place_form.USE_YN}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_Place_form({
                      ...space_Place_form,
                      [name]: value,
                    });
                  }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={space_Place_form.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setSpace_Place_formModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {space_user_modal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setSpace_user_modal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  아이디
                </p>
                <input type="text" value={space_user.USER_ID} disabled />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  대표자명
                </p>
                <input
                  type="text"
                  name="NAME"
                  value={space_user.NAME}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_user({
                      ...space_user,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  이메일
                </p>
                <input
                  type="text"
                  name="EMAIL"
                  value={space_user.EMAIL}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_user({
                      ...space_user,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  연락처
                </p>
                <input
                  type="text"
                  name="PHONE"
                  value={space_user.PHONE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_user({
                      ...space_user,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  소속명
                </p>
                <input
                  type="text"
                  name="COMPANY"
                  value={space_user.COMPANY}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_user({
                      ...space_user,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  사용여부
                </p>
                <select
                  name="USER_STATE"
                  value={space_user.USER_STATE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setSpace_user({
                      ...space_user,
                      [name]: value,
                    });
                  }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={space_user.REG_DT}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setSpace_user_modal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {portfolio_typeModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setPortfolio_typeModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  기수명
                </p>
                <input
                  type="text"
                  name="TYPE"
                  value={portfolio_type.TYPE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPortfolio_type({
                      ...portfolio_type,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setPortfolio_typeModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {portfolio_formModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setPortfolio_formModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  기수명
                </p>
                <select
                  name="TYPE_ID"
                  value={portfolio_form.TYPE_ID}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPortfolio_form({
                      ...portfolio_form,
                      [name]: value,
                    });
                  }}
                >
                  {getTypeList.map((item, index) => (
                    <option key={index} value={item.TYPE_ID}>
                      {item.TYPE}
                    </option>
                  ))}
                </select>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <input
                  type="file"
                  id="portfolio_form_img"
                  onChange={(e) => {
                    setPortfolio_formImg(e.target.files[0]);
                  }}
                />
                <p className="Admin_edit_modal_inner_form_input_title">
                  이미지
                </p>
                <label htmlFor="portfolio_form_img">
                  <p>{portfolio_formImg ? portfolio_formImg.name : ""}</p>
                </label>
              </div>
              <div className="Admin_edit_modal_img">
                <img
                  src={`http://34.64.112.233:8000/file/${portfolio_form.IMG_KEY}`}
                  alt="img"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  기업명
                </p>
                <input
                  type="text"
                  name="COM_NAME"
                  value={portfolio_form.COM_NAME}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPortfolio_form({
                      ...portfolio_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  대표자명
                </p>
                <input
                  type="text"
                  name="NAME"
                  value={portfolio_form.NAME}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPortfolio_form({
                      ...portfolio_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  소개글
                </p>

                <textarea
                  name="CONTENT"
                  value={portfolio_form.CONTENT}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPortfolio_form({
                      ...portfolio_form,
                      [name]: value,
                    });
                  }}
                  maxLength="70"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  표시여부
                </p>
                <select
                  name="SHOW_YN"
                  value={portfolio_form.SHOW_YN}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPortfolio_form({
                      ...portfolio_form,
                      [name]: value,
                    });
                  }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={portfolio_form.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setPortfolio_formModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {partners_typeModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setPartners_typeModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  기관명
                </p>
                <input
                  type="text"
                  name="TYPE"
                  value={partners_type.TYPE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPartners_type({
                      ...partners_type,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setPartners_typeModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {partners_formModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setPartners_formModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  기관명
                </p>
                <select
                  name="TYPE_ID"
                  value={partners_form.TYPE_ID}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPartners_form({
                      ...partners_form,
                      [name]: value,
                    });
                  }}
                >
                  {getTypeList.map((item, index) => (
                    <option key={index} value={item.TYPE_ID}>
                      {item.TYPE}
                    </option>
                  ))}
                </select>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <input
                  type="file"
                  id="portfolio_form_img"
                  onChange={(e) => {
                    setPartners_formImg(e.target.files[0]);
                  }}
                />
                <p className="Admin_edit_modal_inner_form_input_title">
                  이미지
                </p>
                <label htmlFor="portfolio_form_img">
                  <p>{partners_formImg ? partners_formImg.name : ""}</p>
                </label>
              </div>
              <div className="Admin_edit_modal_img">
                <img
                  src={`http://34.64.112.233:8000/file/${partners_form.IMG_KEY}`}
                  alt="img"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  표시여부
                </p>
                <select
                  name="SHOW_YN"
                  value={partners_form.SHOW_YN}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setPartners_form({
                      ...partners_form,
                      [name]: value,
                    });
                  }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={partners_form.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setPartners_formModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}

        {news_formModal && (
          <div className="Admin_edit_modal">
            <div className="Admin_edit_modal_inner_top">
              <p>수정</p>
              <img
                src="../img/pg_close.png"
                alt="img"
                onClick={() => {
                  setNews_formModal(false);
                }}
              />
            </div>
            <div className="Admin_edit_modal_inner_form">
              <div className="Admin_edit_modal_inner_form_input">
                <input
                  type="file"
                  id="portfolio_form_img"
                  onChange={(e) => {
                    setNewsformImg(e.target.files[0]);
                  }}
                />
                <p className="Admin_edit_modal_inner_form_input_title">
                  이미지
                </p>
                <label htmlFor="portfolio_form_img">
                  <p>{newsformImg ? newsformImg.name : ""}</p>
                </label>
              </div>
              <div className="Admin_edit_modal_img">
                <img
                  src={`http://34.64.112.233:8000/file/${news_form.IMG_KEY}`}
                  alt="img"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  기관명
                </p>
                <input
                  type="text"
                  name="HOST_NAME"
                  value={news_form.HOST_NAME}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">일시</p>
                <input
                  type="text"
                  name="START_DATE"
                  value={news_form.START_DATE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  마감일
                </p>
                <input
                  type="text"
                  name="DEADLINE"
                  value={news_form.DEADLINE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">분류</p>
                <input
                  type="text"
                  name="TYPE"
                  value={news_form.TYPE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">제목</p>
                <input
                  type="text"
                  name="TITLE"
                  value={news_form.TITLE}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">내용</p>
                <textarea
                  name="CONTENT"
                  value={news_form.CONTENT}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">링크</p>
                <input
                  type="text"
                  name="LINK"
                  value={news_form.LINK}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  표시여부
                </p>
                <select
                  name="SHOW_YN"
                  value={news_form.SHOW_YN}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setNews_form({
                      ...news_form,
                      [name]: value,
                    });
                  }}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>

              <div className="Admin_edit_modal_inner_form_input">
                <p className="Admin_edit_modal_inner_form_input_title">
                  수정일
                </p>
                <input
                  type="text"
                  value={news_form.DATE}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="Admin_edit_modal_inner_btn">
              <button
                onClick={() => {
                  setNews_formModal(false);
                }}
              >
                취소
              </button>
              <button onClick={onClickEdit}>확인</button>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <h2>테이블에 값이 없습니다.</h2>;
  }
};
export default Admin_table;

import "./Partners.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
const Partners = () => {
  const [typeList, setTypeList] = useState([]);

  const [logoList, setLogoList] = useState([]);
  useEffect(() => {
    // 년도만
    Axios.get("http://34.64.112.233:8000/get/partners_form", {
      params: { key: "type" },
    })
      .then((res) => {
        const resData = res.data;
        setTypeList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
    // 전체 리스트
    Axios.get("http://34.64.112.233:8000/get/partners_form", {
      params: { key: "all" },
    })
      .then((res) => {
        const resData = res.data;
        setLogoList(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (typeList.length !== 0) {
    return (
      <>
        <div className="Partners_inner">
          <p className="Partners_title">Partners</p>
          {typeList.map((itemType, index) => (
            <div key={index} className="Partners_box">
              <p>{itemType.TYPE}</p>
              <div className="Partners_content">
                {logoList.map((item, index) =>
                  itemType.TYPE === item.TYPE ? (
                    <div key={index} className="Partners_logo">
                      <img
                        src={`http://34.64.112.233:8000/file/${item.IMG_KEY}`}
                        alt="img"
                      />
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  } else {
    return <div className="Loading">{/* <h2>Loading...</h2> */}</div>;
  }
};
export default Partners;
